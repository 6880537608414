import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as SparkMD5 from "spark-md5";
import { Base64 } from "js-base64";
import { each } from "lodash";
import { parse as parseQs, stringify as stringifyQs } from "query-string";
import { OrderDirection, ProductOrderField } from "../../gqlTypes/globalTypes";
export var slugify = function slugify(text) {
  return text.toString().toLowerCase().trim().replace(/\s+/g, "-") // Replace spaces with -
  .replace(/&/g, "-and-") // Replace & with 'and'
  .replace(/[^\w\-]+/g, "") // Remove all non-word chars
  .replace(/\-\-+/g, "-");
}; // Replace multiple - with single -

export var getDBIdFromGraphqlId = function getDBIdFromGraphqlId(graphqlId, schema) {
  // This is temporary solution, we will use slugs in the future
  var rawId = Base64.decode(graphqlId);
  var regexp = /(\w+):(\d+)/;
  var arr = regexp.exec(rawId);

  if (schema && schema !== arr[1]) {
    throw new Error("Schema is not correct");
  }

  return parseInt(arr[2], 10);
};
export var getGraphqlIdFromDBId = function getGraphqlIdFromDBId(id, schema) {
  return (// This is temporary solution, we will use slugs in the future
    Base64.encode("".concat(schema, ":").concat(id))
  );
};
export var priceToString = function priceToString(price, locale) {
  var amount = price.amount;

  if (locale) {
    return amount.toLocaleString(locale, {
      currency: price.currency,
      style: "currency"
    });
  }

  return "".concat(price.currency, " ").concat(amount.toFixed(2));
};
export var generateProductUrl = function generateProductUrl(id, name) {
  return "/product/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Product"), "/");
};
export var baseFileUrl = function baseFileUrl(baseUrl, type, hash) {
  return "".concat(baseUrl, "/media/").concat(type.slice(-3), "/").concat(hash, "/");
}; // export const generateFileUrl = (
//   baseUrl: string,
//   type: string,
//   hash: string,
//   ext: string
// ) => `${baseFileUrl(baseUrl, type, hash)}${hash}.${ext}`;

export var generateFileThumbUrl = function generateFileThumbUrl(baseUrl, type, hash, name) {
  var hexColor = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "000000";
  return "".concat(baseFileUrl(baseUrl, type, hash), "covers/cover-").concat(hexColor, ".png");
}; // ${type === "img" ? name.split(".").pop() : "png"}`;

export var generateTesiProductUrl = function generateTesiProductUrl(id, name) {
  var fileType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pdf";
  var fileHash = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "def";
  return "/tesi-product/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Product"), "/").concat(fileType, "/").concat(fileHash);
};
export var generateCategoryUrl = function generateCategoryUrl(id, name) {
  return "/category/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Category"), "/");
};
export var generateCollectionUrl = function generateCollectionUrl(id, name) {
  return "/collection/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Collection"), "/");
};
export var generatePageUrl = function generatePageUrl(slug) {
  return "/page/".concat(slug, "/");
};
export var convertToAttributeScalar = function convertToAttributeScalar(attributes) {
  return Object.entries(attributes).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return value.map(function (attribute) {
      return {
        slug: key,
        value: attribute
      };
    });
  }).reduce(function (prev, curr) {
    return [].concat(_toConsumableArray(prev), _toConsumableArray(curr));
  }, []);
};
export var getAttributesFromQs = function getAttributesFromQs(qs) {
  return Object.keys(qs).filter(function (key) {
    return !["pageSize", "priceGte", "priceLte", "sortBy", "q"].includes(key);
  }).reduce(function (prev, curr) {
    prev[curr] = typeof qs[curr] === "string" ? [qs[curr]] : qs[curr];
    return prev;
  }, {});
};
export var getValueOrEmpty = function getValueOrEmpty(value) {
  return value === undefined || value === null ? "" : value;
};
export var convertSortByFromString = function convertSortByFromString(sortBy) {
  if (!sortBy) {
    return null;
  }

  var direction = sortBy.startsWith("-") ? OrderDirection.DESC : OrderDirection.ASC;
  var field;

  switch (sortBy.replace(/^-/, "")) {
    case "name":
      field = ProductOrderField.NAME;
      break;

    case "price":
      field = ProductOrderField.MINIMAL_PRICE;
      break;

    case "updated_at":
      field = ProductOrderField.DATE;
      break;

    default:
      return null;
  }

  return {
    field: field,
    direction: direction
  };
};
export var maybe = function maybe(exp, d) {
  try {
    var result = exp();
    return result === undefined ? d : result;
  } catch (_unused) {
    return d;
  }
};
export var parseQueryString = function parseQueryString(location) {
  var query = parseQs(window.location.search.substr(1));
  each(query, function (value, key) {
    if (Array.isArray(value)) {
      query = _objectSpread({}, query, _defineProperty({}, key, value[0]));
    }
  });
  return query;
};
export var updateQueryString = function updateQueryString(location, history) {
  var querystring = parseQueryString(location);
  return function (key, value) {
    if (value === "") {
      delete querystring[key];
    } else {
      querystring[key] = value || key;
    }

    history.replace("?".concat(stringifyQs(querystring)));
  };
};
export var findFormErrors = function findFormErrors(result) {
  if (result) {
    var data = Object.values(maybe(function () {
      return result.data;
    }));
    return data.reduce(function (prevVal, currVal) {
      var errors = currVal.errors || [];
      return [].concat(_toConsumableArray(prevVal), _toConsumableArray(errors));
    }, []);
  }

  return [];
};
export var removeEmptySpaces = function removeEmptySpaces(text) {
  return text.replace(/\s+/g, "");
};
/* eslint-disable prefer-promise-reject-errors */

/* eslint-disable no-lonely-if */

export function computeChecksumMd5(file) {
  var base64 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return new Promise(function (resolve, reject) {
    var chunkSize = 2097152; // Read in chunks of 2MB

    var spark = new SparkMD5.ArrayBuffer();
    var fileReader = new FileReader();
    var cursor = 0; // current cursor in file

    fileReader.onerror = function () {
      return reject("MD5 computation failed - error reading the file");
    }; // read chunk starting at `cursor` into memory


    function processChunk(chunk_start) {
      var chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    fileReader.onload = function (e) {
      spark.append(e.target.result); // Accumulate chunk to md5 computation

      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the base64 encoded md5 hash, which is what
        // Rails ActiveStorage or cloud services expect
        if (base64) {
          resolve(btoa(spark.end(true)));
        } else {
          resolve(spark.end());
        } // If you prefer the hexdigest form (looking like
        // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
        // resolve(spark.end());

      }
    };

    processChunk(0);
  });
}