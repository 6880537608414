import { UploadTypes } from "gqlTypes/globalTypes";
import { uploadCoverMessages, uploadDOCMessages, uploadIMGMessages, uploadOtherMessages, uploadPDFMessages, uploadPlotterMessages, uploadPPTMessages, uploadThesisMessages } from "./intl";
export var MONEY = {
  id: "17",
  graphId: "UHJvZHVjdDoxNw==",
  slug: "money-product",
  euros: {
    id: "253",
    graphId: "UHJvZHVjdFZhcmlhbnQ6MjUz",
    sku: "Euros"
  },
  coins: {
    id: "254",
    graphId: "UHJvZHVjdFZhcmlhbnQ6MjU0",
    sku: "Coins"
  }
};
export var INNER_PAGE = {
  id: "2",
  slug: "inner-page-product",
  colored: {
    simplex: "Colored-Simplex",
    duplex: "Colored-Duplex"
  },
  grey: {
    simplex: "Grey-Simplex",
    duplex: "Grey-Duplex"
  }
};
export var PHOTO = {
  id: "5",
  // 5 dev 11
  slug: "stampa-photos",
  name: "Stampa Photos"
};
export var PLOTTER = {
  id: "4",
  // 4 dev 9
  slug: "stampa-plotter"
};
export var INCISIONE = {
  id: "QXR0cmlidXRlOjc=",
  slug: "cover-text-color",
  text: "text-color"
};
export var COVER = {
  id: "1",
  slug: "cover-product",
  name: "Tipo di Copertina",
  text: "text-color",
  color: "cover-color",
  type: "cover-type"
};
export var UPLOAD_TYPES = [{
  id: "id0",
  name: "Print tesi file (PDF)",
  type: "thesis-pdf",
  messages: uploadThesisMessages,
  uploadType: UploadTypes.THESIS_PDF,
  mime: "application/pdf",
  url: "/tesi-product/".concat(COVER.slug, "/").concat(COVER.id, "/thesis-pdf/")
}, {
  id: "id1",
  name: "Print simple file (PDF)",
  type: "pdf",
  messages: uploadPDFMessages,
  uploadType: UploadTypes.PDF,
  mime: "application/pdf",
  url: "/pages-preview/pdf/"
}, {
  id: "id2",
  name: "Print slides (PPT/PPTX)",
  type: "ppt",
  messages: uploadPPTMessages,
  uploadType: UploadTypes.PPT,
  mime: ".ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  url: "/upload/ppt/"
}, {
  id: "id3",
  name: "Print Image (PNG/JPG/SVG/GIF/TIF/BMP/PSD)",
  type: "img",
  messages: uploadIMGMessages,
  uploadType: UploadTypes.IMG,
  mime: ".png,.jpg,.svg,.gif,.tif,.bmp,.psd,image/png,image/jpeg,image/gif,image/tiff,image/bmp,image/vnd.adobe.photoshop",
  url: "/select-format/img/"
}, {
  id: "id4",
  name: "MS Word document",
  type: "doc",
  messages: uploadDOCMessages,
  uploadType: UploadTypes.DOC,
  mime: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  url: "/upload/doc/"
}, {
  id: "id5",
  name: "Other types of file",
  type: "other",
  messages: uploadOtherMessages,
  uploadType: UploadTypes.OTHER,
  mime: "*.*",
  url: "/upload/other/"
}, {
  id: "id6",
  name: "Upload file in PDF format",
  type: "plotter-pdf",
  messages: uploadPlotterMessages,
  uploadType: UploadTypes.PLOTTER_PDF,
  mime: "application/pdf",
  url: "/product/".concat(PLOTTER.slug, "/").concat(PLOTTER.id, "/plotter-pdf/")
}, {
  id: "id-1",
  name: "Upload cover file (PDF)",
  type: "cover-pdf",
  messages: uploadCoverMessages,
  uploadType: UploadTypes.COVER_PDF,
  mime: "application/pdf",
  url: "/tesi-product/cover-product/".concat(COVER.id, "/thesis-pdf/")
}];