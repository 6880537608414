import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  query FileExists($hash: String!) {\n    fileExists(fileHash: $hash)\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query UploadedFiles($first: Int, $file_ids: [ID], $file_hashs: [String]) {\n    uploadedFiles(first: $first, ids: $file_ids, hashs: $file_hashs) {\n      totalCount\n      edges {\n        node {\n          ...UploadedFileFragment\n        }\n      }\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  mutation UploadFileLineClear($fileLineId: ID!) {\n    clearFileLine(id: $fileLineId) {\n      ok\n      uploadErrors {\n        field\n        message\n        code\n      }\n      uploadedFileLine {\n        id\n        name\n        hash\n        created\n        role\n        checkout {\n          token\n        }\n        order {\n          token\n        }\n        file {\n          created\n          extension\n        }\n      }\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  mutation UploadFileLineCreate(\n    $file_line_input: UploadFileLineInput!\n    $hash: String!\n  ) {\n    createFileLine(hash: $hash, input: $file_line_input) {\n      ok\n      fileLine {\n        id\n        hash\n        name\n        order {\n          id\n          token\n        }\n        checkout {\n          token\n        }\n        choosedColoredPages # [1, 5, 11]\n        duplex # false\n        copies # 1\n        role\n        file {\n          ...UploadedFileFragment\n        }\n        created\n        pagesPerSheet\n        # elements {\n        #   id\n        #   variant {id, sku}\n        #   attribute {slug}\n        #   attributeValue {slug}\n        # }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  mutation UploadFile(\n    $file: Upload!\n    $hash: String!\n    $uploadType: UploadTypes!\n  ) {\n    uploadFile(file: $file, fileHash: $hash, uploadType: $uploadType) {\n      info\n      uploadedFile {\n        ...UploadedFileFragment\n      }\n      uploadErrors {\n        ...UploadFileErrorFragment\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment UploadFileErrorFragment on UploadFileError {\n    field\n    message\n    code\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment UploadedFileFragment on UploadedFile {\n    id\n    hash\n    name\n    extension\n    baseUrl\n    pageCount\n    coloredPages\n    created\n    textAttribute {\n      id\n      name\n      slug\n      values {\n        id\n        name\n        slug\n      }\n    }\n    thumbnail\n    url\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag"; // import { uploadErrorFragment } from "@saleor/fragments/errors";
// export enum FilePrintErrorCode {
//   CONVERSION_ERROR = "CONVERSION_ERROR",
//   FILE_EXISTS = "FILE_EXISTS",
//   GRAPHQL_ERROR = "GRAPHQL_ERROR",
//   INVALID_FILETYPE = "INVALID_FILETYPE",
//   OS_ERROR = "OS_ERROR",
// }
// export const typeDefs = gql`
//   enum UploadTypes {
//     THESIS_PDF
//     COVER_PDF
//     PDF
//     IMG
//     DOC
//     PPT
//     OTHER
//   }
// `;
// TODO: Describe uploadFile & fileExists mutation types

export var uploadedFileFragment = gql(_templateObject());
export var uploadFileErrorFragment = gql(_templateObject2());
export var uploadFile = gql(_templateObject3(), uploadedFileFragment, uploadFileErrorFragment);
export var fileLineCreate = gql(_templateObject4(), uploadedFileFragment);
export var fileLineClear = gql(_templateObject5());
export var uploadedFiles = gql(_templateObject6(), uploadedFileFragment);
export var fileExists = gql(_templateObject7()); // export const createCover = gql`
//   ${uploadErrorFragment}
//   mutation CreateCover($hash: String!) {
//     createCover(fileHash: $hash) {
//       cover {
//         id
//         url
//       }
//       errors: uploadErrors {
//         ...UploadErrorFragment
//       }
//     }
//   }
// `;
// export const getCover = gql`
//   query GetCover($hash: String!) {
//     cover(fileHash: $hash) {
//       id
//       url
//       errors: uploadErrors {
//         ...UploadErrorFragment
//       }
//     }
//   }
// `;