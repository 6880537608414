import React from "react";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import { BraintreePaymentGateway, DummyPaymentGateway, StripePaymentGateway, AdyenPaymentGateway, TapPayPaymentGateway } from "..";
import * as S from "./styles";

/**
 * Payment Gateways list
 */
var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.BRAINTREE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBraintreeInput",
          name: "payment-method",
          value: "credit-card",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBraintreeName"
        }, name))), checked && React.createElement(BraintreePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            return _processPayment(id, token);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.STRIPE.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayStripeInput",
          name: "payment-method",
          value: "stripe",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayStripeName",
          style: {
            width: 240
          }
        }, React.createElement(FormattedMessage, checkoutMessages.stripeLabel)), React.createElement(S.SVGContainer, null, React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "Visa"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
          fill: "#FFF",
          d: "M0 4h35v17H0z"
        }), React.createElement("path", {
          fill: "#191E70",
          d: "M4 0h27a4 4 0 0 1 4 4H0a4 4 0 0 1 4-4z"
        }), React.createElement("path", {
          fill: "#F7B600",
          d: "M0 21h35a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        }), React.createElement("path", {
          fill: "#191E70",
          fillRule: "nonzero",
          d: "M16.86 8.22l-2.02 9.7h-2.46l2.02-9.7h2.46zm10.16 6.27l1.3-3.66.72 3.66h-2.02zm2.74 3.43H32l-1.95-9.7h-2.09c-.43 0-.86.3-1 .75l-3.61 8.95h2.52l.5-1.42h3.1l.3 1.42zm-6.34-3.2c0-2.54-3.4-2.7-3.4-3.81.08-.52.51-.82 1.02-.82a4.4 4.4 0 0 1 2.38.45l.43-2.1A5.97 5.97 0 0 0 21.62 8c-2.38 0-4.12 1.34-4.12 3.2 0 1.43 1.23 2.17 2.1 2.62.93.45 1.3.75 1.22 1.2 0 .66-.72.96-1.44.96-.87 0-1.73-.22-2.52-.6l-.44 2.1c.87.37 1.8.52 2.67.52 2.67.07 4.33-1.27 4.33-3.29zm-10.03-6.5l-3.89 9.7H6.9l-1.94-7.76c0-.37-.3-.67-.58-.82A8.21 8.21 0 0 0 2 8.52l.07-.3h4.11c.58 0 1.01.45 1.08.97l1.01 5.6 2.6-6.57h2.52z"
        }))), React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "Mastercard"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
          width: "35",
          height: "25",
          fill: "#353A48",
          rx: "4"
        }), React.createElement("circle", {
          cx: "12.5",
          cy: "12.5",
          r: "7.5",
          fill: "#ED0006"
        }), React.createElement("circle", {
          cx: "22.5",
          cy: "12.5",
          r: "7.5",
          fill: "#F9A000"
        }), React.createElement("path", {
          fill: "#FF5D00",
          d: "M17.5 6.91a7.48 7.48 0 0 1 0 11.18 7.48 7.48 0 0 1-.06-11.13z"
        }))), React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "American Express"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
          width: "35",
          height: "25",
          fill: "#017ECD",
          rx: "4"
        }), React.createElement("path", {
          fill: "#FFF",
          fillRule: "nonzero",
          d: "M17.57 12.47v-.68c0-.26-.27-.53-.53-.53h-.75v1.21h-.8V9.05h1.97c.49-.05.97.27 1.02.74v.26c0 .32-.16.63-.48.8.27.15.43.47.37.78v.84h-.8zm-1.33-2h.85c.16.06.37-.05.43-.2.05-.16-.06-.38-.22-.43h-1.06v.63zm11.38 2l-1.29-2.31v2.31h-1.6l-.27-.73h-1.5l-.26.73H21s-1.08-.15-1.08-1.63c0-1.79.91-1.84 1.28-1.84h1.02v.79h-.8c-.38.05-.64.37-.59.74v.26c0 1.16 1.39.79 1.44.79L23.3 9h1.12l1.23 3.1V9.06h1.12l1.28 2.27V9.05h.8v3.48l-1.22-.06zm-4.28-1.58h.8l-.37-1-.43 1zm-12.13 1.58v-2.36l-1.01 2.36h-.64l-1.02-2.36v2.36h-1.6l-.27-.73h-1.5l-.26.73H4L5.34 9h1.12l1.23 3.16v-3.1h1.28l.9 2.15.92-2.16h1.28v3.48l-.86-.06zM5.5 10.9h.8l-.38-1-.42 1zm7 1.58V9.05H15v.8h-1.7v.52h1.65v.79H13.3v.58H15v.73h-2.5zm6.3.06V9.05h.8v3.48h-.8zm1.76 4.42v-.69c0-.31-.26-.52-.58-.52h-.86v1.2h-.85v-3.41h2.08a.89.89 0 0 1 1.07.79v.2c0 .32-.16.64-.48.8.26.15.42.47.42.79v.84h-.8zm-1.39-2h.91c.16.05.38-.06.43-.21.05-.16-.05-.37-.21-.42a.16.16 0 0 0-.22 0h-.9v.63zm-4.32 2h-.75l-.91-1-.9 1H9.07v-3.42h3.15l.96 1.1 1.02-1.1h2.72c.53-.06 1.02.26 1.07.79v.2c0 1-.38 1.32-1.5 1.32h-.85v1.1h-.8zm-1.07-1.69l1.07 1.21v-2.36l-1.07 1.15zm-3.85.95h1.82l.85-.95-.85-.94H9.93v.52h1.76v.8H9.93v.57zm5.77-1.26h.91c.16.05.37-.06.43-.21.05-.16-.06-.37-.22-.42a.16.16 0 0 0-.21 0h-.9v.63zm10.74 2H24.9v-.8h1.34s.48.06.48-.26c0-.31-.75-.26-.75-.26-.59.1-1.07-.31-1.18-.84v-.16c-.05-.52.38-1 .91-1.05h1.82v.74h-1.34s-.48-.1-.48.26c0 .26.64.26.64.26s1.34-.1 1.34.95a1.1 1.1 0 0 1-.96 1.21h-.22l-.05-.05zm-4.6 0v-3.42h2.68v.79H22.7v.52h1.76v.8H22.7v.57h1.82v.74h-2.67zm7.6 0h-1.56v-.8h1.34s.48.06.48-.26c0-.31-.75-.26-.75-.26-.59.1-1.07-.31-1.17-.9v-.15c-.06-.53.37-1 .9-1.05h1.82v.79h-1.33s-.49-.1-.49.26c0 .26.65.26.65.26s1.33-.1 1.33.95A1.1 1.1 0 0 1 29.7 17h-.21c0-.05 0-.05-.06-.05z"
        }))), React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "Discover"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
          width: "35",
          height: "25",
          fill: "#FFA201",
          rx: "4"
        }), React.createElement("path", {
          fill: "#FFF",
          fillRule: "nonzero",
          d: "M14.55 10.1h.15c.35 0 .75.05 1.05.25v1a1.4 1.4 0 0 0-1.05-.5 1.5 1.5 0 0 0-1.4 1.55v.1c0 .8.65 1.4 1.45 1.4.4 0 .75-.2 1-.5v1c-.35.15-.7.25-1.1.25a2.28 2.28 0 0 1-2.25-2.3c0-1.3 1-2.35 2.3-2.25zm6.85 0l1.15 3 1.2-3h.9l-1.85 4.55h-.45L20.5 10.1h.9zM10.57 10h.13c.45 0 .9.2 1.25.5l-.45.6c-.2-.2-.45-.35-.7-.35-.3-.05-.55.2-.6.5 0 .25.15.35.65.55 1 .4 1.25.7 1.3 1.3v.15c-.05.8-.7 1.4-1.5 1.35-.6 0-1.2-.3-1.5-.85l.55-.55c.15.35.5.6.9.6h.05c.3 0 .6-.3.6-.65 0-.2-.1-.35-.25-.45-.2-.1-.4-.2-.6-.25-.8-.25-1.05-.6-1.05-1.2v-.05c.05-.7.65-1.25 1.35-1.2zm-5.07.1a2.18 2.18 0 0 1 2.1 2.25c0 .65-.3 1.25-.8 1.7-.45.35-1 .55-1.55.5H4V10.1zm3.3 0v4.45h-.85V10.1h.85zm18.65 0v.75H25.9v1h1.5v.75h-1.5v1.2h1.55v.75h-2.4V10.1h2.4zm1.8 0c1 0 1.55.45 1.55 1.3.05.65-.4 1.2-1 1.3l1.35 1.85H30.1l-1.15-1.8h-.1v1.8H28V10.1zm-24 .74l-.15.01h-.25v2.95h.25c.4.05.85-.1 1.15-.35.3-.3.45-.7.45-1.15 0-.4-.15-.8-.45-1.1-.3-.25-.75-.4-1.15-.35zm23.85-.04h-.25v1.35h.25c.55 0 .8-.25.8-.7 0-.4-.25-.65-.8-.65z"
        }), React.createElement("path", {
          fill: "#FFF",
          fillRule: "nonzero",
          d: "M18.35 10.05c-1.25 0-2.3 1-2.3 2.3 0 1.25 1 2.3 2.3 2.35 1.3.05 2.3-1 2.35-2.3a2.38 2.38 0 0 0-2.35-2.35z",
          opacity: ".8"
        }))), React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod PaymentsCheckoutForm__paymentMethod--hiddenOnMobile",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "Diners Club"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
          width: "35",
          height: "25",
          fill: "#2283CB",
          rx: "4"
        }), React.createElement("path", {
          fill: "#FFF",
          fillRule: "nonzero",
          d: "M18.98 5a7.57 7.57 0 0 1 7.63 7.5v.07A7.64 7.64 0 0 1 18.98 20h-2.67A7.45 7.45 0 0 1 9 12.57 7.29 7.29 0 0 1 15.97 5h3zM16.5 6.3c-3.39 0-6.2 2.8-6.2 6.2 0 3.4 2.81 6.2 6.2 6.2 3.4 0 6.2-2.8 6.2-6.2 0-3.4-2.8-6.2-6.2-6.2z"
        }), React.createElement("path", {
          fill: "#FFF",
          fillRule: "nonzero",
          d: "M17.48 16.67V8.4a4.26 4.26 0 0 1 0 8.28zm-1.96 0a4.29 4.29 0 0 1 0-8.28v8.28z"
        }))), React.createElement(S.SVG, {
          className: "PaymentLogo PaymentsCheckoutForm__paymentMethod PaymentsCheckoutForm__paymentMethod--hiddenOnMobile",
          width: "35",
          height: "25",
          viewBox: "0 0 35 25",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("title", null, "JCB"), React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
          width: "35",
          height: "25",
          fill: "#FFF",
          rx: "4"
        }), React.createElement("path", {
          fill: "#46A548",
          fillRule: "nonzero",
          d: "M26.2 3C24.4 3 23 4.4 23 6.2V9h4.9c.9-.1 1.7.7 1.8 1.6v.1c0 .9-.6 1.6-1.5 1.7 1 0 1.8.8 1.9 1.8 0 1-.9 1.8-1.9 1.8H23v6h4.7c1.8 0 3.2-1.4 3.2-3.2V3h-4.7zM25 14.9h2.3c.5-.2.8-.7.7-1.3-.1-.3-.4-.6-.7-.7H25v2zm2.8-4c0-.4-.3-.8-.7-.9H25v1.8h2.1c.4-.1.7-.5.7-.9z"
        }), React.createElement("path", {
          fill: "#D02644",
          fillRule: "nonzero",
          d: "M17.2 3C15.4 3 14 4.4 14 6.2V10c.8-.8 2.2-1.3 4.5-1.2.9.1 1.7.2 2.5.4v1.5c-.7-.4-1.6-.7-2.4-.8-1.4-.1-2.6.9-2.8 2.2-.1 1.4.9 2.6 2.2 2.8h.5c.9-.1 1.7-.4 2.4-.8v1.5c-.8.2-1.7.4-2.5.4-2.3.1-3.7-.4-4.5-1.2v7h4.8c1.8 0 3.2-1.4 3.2-3.2V3h-4.7z"
        }), React.createElement("path", {
          fill: "#1B6BAF",
          fillRule: "nonzero",
          d: "M5 6.2v8c.8.5 1.7.8 2.7.8.9 0 1.6-.7 1.7-1.6V9h2.7v4.2c0 1.6-.9 3-3.8 3-1.1 0-2.2-.2-3.2-.4V22h4.8c1.8 0 3.2-1.4 3.2-3.2V3H8.2C6.4 3 5 4.4 5 6.2z"
        })))))), checked && React.createElement(StripePaymentGateway, {
          config: config,
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token, cardData) {
            return _processPayment(id, token, cardData);
          },
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.ADYEN.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayAdyenInput",
          name: "payment-method",
          value: "adyen",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayAdyenName"
        }, name))), checked && React.createElement(AdyenPaymentGateway, {
          config: config,
          formRef: formRef,
          scriptConfig: PROVIDERS.ADYEN.script,
          styleConfig: PROVIDERS.ADYEN.style,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: errors,
          onError: onError
        }));

      case PROVIDERS.TAPPAY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayTapPayInput",
          name: "payment-method",
          value: "tappay",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayTapPayName"
        }, name))), checked && React.createElement(TapPayPaymentGateway // config={config}
        // scriptConfig={PROVIDERS.TAPPAY.script}
        // styleConfig={PROVIDERS.TAPPAY.style}
        , {
          formRef: formRef,
          processPayment: function processPayment() {
            return _processPayment(id);
          },
          submitPayment: submitPayment,
          submitPaymentSuccess: submitPaymentSuccess,
          errors: errors,
          onError: onError
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };