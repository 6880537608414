import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  min-width: 110px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    width: 88%;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin: 20px auto;\n  max-width: 360px;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  margin: 0 auto;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n    min-width: 300px;\n    padding: 20px 0;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: white;\n  min-width: 420px;\n  width: 50%;\n  max-width: 800px;\n  margin: 20px auto;\n  padding: 20px;\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 4px 5px;\n  background-color: rgba(255, 255, 255, 0.7);\n  max-width: 840px;\n  width: fit-content;\n  margin: 0 auto 8px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var HelpText = styled.div(_templateObject());
export var PayForm = styled.form(_templateObject2(), media.smallScreen(_templateObject3()));
export var Input = styled.div(_templateObject4(), function (props) {
  return props.theme.spacing.spacer;
});
export var InputWithButton = styled.div(_templateObject5());
export var InputWrapper = styled.div(_templateObject6());
export var QuantityInput = styled.div(_templateObject7(), media.smallScreen(_templateObject8()));
export var ButtonWrapper = styled.div(_templateObject9());