import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { useMutation } from "@apollo/client";
import * as S from "./styles";
import { fileLinesSetToken } from "./mutations";

var CostLine = function CostLine(_ref) {
  var name = _ref.name,
      cost = _ref.cost,
      _ref$last = _ref.last,
      last = _ref$last === void 0 ? false : _ref$last,
      _ref$negative = _ref.negative,
      negative = _ref$negative === void 0 ? false : _ref$negative;
  return React.createElement(S.CostLine, {
    last: last
  }, React.createElement("span", null, name), React.createElement("span", {
    "data-test": "cartSummaryCost".concat(name.replace(/\s/g, ""))
  }, negative && "- ", React.createElement(TaxedMoney, {
    taxedMoney: cost
  })));
};

var Costs = function Costs(_ref2) {
  var subtotal = _ref2.subtotal,
      promoCode = _ref2.promoCode,
      shipping = _ref2.shipping,
      total = _ref2.total;
  var intl = useIntl();
  return React.createElement(S.Costs, null, subtotal && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.subtotal),
    cost: subtotal
  }), shipping && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.shipping),
    cost: shipping
  }), promoCode && promoCode.gross.amount > 0 && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.promoCode),
    cost: promoCode,
    negative: true
  }), total && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.total),
    cost: total,
    last: true
  }));
};
/**
 * Cart summary displayed in checkout page
 */


var CartSummary = function CartSummary(_ref3) {
  var subtotal = _ref3.subtotal,
      total = _ref3.total,
      shipping = _ref3.shipping,
      promoCode = _ref3.promoCode,
      products = _ref3.products,
      fileLines = _ref3.fileLines,
      checkoutToken = _ref3.checkoutToken;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      mobileCartOpened = _useState2[0],
      setMobileCartOpened = _useState2[1];

  var _useState3 = useState(!!checkoutToken),
      _useState4 = _slicedToArray(_useState3, 2),
      setted = _useState4[0],
      setSetted = _useState4[1];

  var _useMutation = useMutation(fileLinesSetToken),
      _useMutation2 = _slicedToArray(_useMutation, 1),
      FileLinesUpdateToken = _useMutation2[0];

  var _useState5 = useState(JSON.parse(localStorage.getItem("files_data") || '{"files":[],"fileLines":[]}')),
      _useState6 = _slicedToArray(_useState5, 2),
      parsedData = _useState6[0],
      setParsedData = _useState6[1];

  var _useState7 = useState(fileLines && fileLines.length !== 0 ? fileLines : parsedData.fileLines),
      _useState8 = _slicedToArray(_useState7, 2),
      lines = _useState8[0],
      setLines = _useState8[1];

  useEffect(function () {
    if (checkoutToken) {
      if (!setted) setSetted(parsedData.fileLines.filter(function (el) {
        var _el$checkout;

        return (el === null || el === void 0 ? void 0 : (_el$checkout = el.checkout) === null || _el$checkout === void 0 ? void 0 : _el$checkout.token) !== checkoutToken;
      }).count === 0);
      if (!setted) setFileLinesToken(checkoutToken, FileLinesUpdateToken, parsedData.fileLines, parsedData.files);
    }
  }, [checkoutToken]);

  var setFileLinesToken = function setFileLinesToken(token, mutation, fileLines, files) {
    if (!fileLines || fileLines.length < 1) return;
    var ids = fileLines.map(function (line) {
      return line.id;
    });
    var hashs = fileLines.map(function (line) {
      return line.hash;
    });
    mutation({
      variables: {
        ids: ids,
        token: token,
        hashs: hashs
      }
    }).then(function (response) {
      setLines(response.data.updateFileLines.fileLines);
      var newData = {
        fileLines: response.data.updateFileLines.fileLines,
        files: files
      };
      setParsedData(newData);
      localStorage.setItem("files_data", JSON.stringify(newData));
      setSetted(true);
    });
  };

  return React.createElement(S.Wrapper, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.Title, {
    "data-test": "cartSummaryTitle",
    onClick: function onClick() {
      return setMobileCartOpened(!mobileCartOpened);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartSummary.CartSummary.2088494495",
    defaultMessage: "Cart Summary"
  }), React.createElement(S.ArrowUp, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(Icon, {
    name: "arrow_up",
    size: 24
  }))), React.createElement(S.Content, null, React.createElement(S.HR, null), React.createElement(S.CartSummaryProductList, null, products === null || products === void 0 ? void 0 : products.map(function (product, index) {
    return React.createElement("div", {
      key: product.sku
    }, React.createElement(S.ProductLine, null, React.createElement(CartSummaryRow, {
      index: index,
      sku: product.sku,
      quantity: product.quantity,
      name: product.name,
      price: product.price,
      thumbnail: product.thumbnail,
      activeFrontespiece: product.activeFrontespiece
    })), React.createElement(S.HR, null));
  })), React.createElement(Costs, {
    subtotal: subtotal,
    total: total,
    shipping: shipping,
    promoCode: promoCode
  })), lines && React.createElement("div", null, React.createElement(S.Title, {
    "data-test": "fileSummaryTitle"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartSummary.CartSummary.4029601948",
    defaultMessage: "Files Summary"
  }), " (", lines.length, ")"), React.createElement(S.Content, null, React.createElement(S.CartSummaryFileList, null, lines === null || lines === void 0 ? void 0 : lines.map(function (line, index) {
    var _line$file;

    return React.createElement("div", {
      key: line.id
    }, line.role === "img" || lines.length > 5 ? React.createElement("div", {
      style: {
        margin: "10px"
      }
    }, index + 1, ". ", line.name) : React.createElement("div", {
      style: {
        margin: "10px"
      }
    }, "name: ", line.name, React.createElement("br", null), line.hash, React.createElement("br", null), "pageCount: ", (_line$file = line.file) === null || _line$file === void 0 ? void 0 : _line$file.pageCount, React.createElement("br", null), "colored: ", line.choosedColoredPages, React.createElement("br", null), "Type: ", line.role, React.createElement("br", null)));
  })))));
};

export { CartSummary };