import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { uploadFileMessages } from "@temp/intl";
import { Radio } from "@components/atoms"; // ErrorMessage

import DropFile from "@temp/components/DropFile";
import { useCart } from "@saleor/sdk";
import { HelpText, Tile } from "./styles";
import { computeChecksumMd5, generateFileThumbUrl } from "../../core/utils";
import { Button, OfflinePlaceholder, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { uploadFile, uploadedFiles, fileLineCreate, fileLineClear } from "./queries";
import { UPLOAD_TYPES } from "../../consts";

/* eslint-disable no-console */
var Page = function Page(_ref) {
  var _selectedUploadType$m;

  var isOnline = _ref.isOnline,
      fileType = _ref.fileType,
      fileHash = _ref.fileHash,
      formatVariant = _ref.formatVariant,
      backgroundImage = _ref.backgroundImage;
  var history = useHistory();
  var apolloClient = useApolloClient();
  var overlayContext = React.useContext(OverlayContext);

  var _useState = useState(""),
      _useState2 = _slicedToArray(_useState, 2),
      coverPreviewUrl = _useState2[0],
      setCoverPreviewUrl = _useState2[1];

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      disabledNext = _useState4[0],
      setDisabledNext = _useState4[1];

  var _useState5 = useState([]),
      _useState6 = _slicedToArray(_useState5, 2),
      filesData = _useState6[0],
      setFilesData = _useState6[1];

  useEffect(function () {
    console.log("filesData:", filesData);
    setDisabledNext(!filesData.length || !!filesData.find(function (el) {
      return !el.uploaded;
    }));
  }, [filesData]);
  var baseUrl = new URL("/", process.env.API_URI).origin;

  var _useMutation = useMutation(uploadFile),
      _useMutation2 = _slicedToArray(_useMutation, 1),
      uploadFileMutation = _useMutation2[0];

  var _useMutation3 = useMutation(fileLineCreate),
      _useMutation4 = _slicedToArray(_useMutation3, 1),
      createFileLineMutation = _useMutation4[0];

  var _useMutation5 = useMutation(fileLineClear),
      _useMutation6 = _slicedToArray(_useMutation5, 1),
      clearFileLineMutation = _useMutation6[0];

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      removeItem = _useCart.removeItem,
      items = _useCart.items,
      loaded = _useCart.loaded; // I use Effect for executing cleaning only once per one page load


  useEffect(function () {
    console.log("UploadPage useEffect fileHash:", fileHash, "window.location:", window.location, window.location.hash);

    if (!fileHash && window.location.hash !== "#another") {
      var files_data = JSON.parse(localStorage.getItem("files_data"));
      var lines = (files_data === null || files_data === void 0 ? void 0 : files_data.fileLines.filter(function (line) {
        return line === null || line === void 0 ? void 0 : line.checkout;
      })) || [];
      var data_checkout = JSON.parse(localStorage.getItem("data_checkout"));
      var file_lines = (data_checkout === null || data_checkout === void 0 ? void 0 : data_checkout.fileLines) || [];
      var line_ids = new Set([].concat(_toConsumableArray(file_lines.map(function (line) {
        return line.id;
      })), _toConsumableArray(lines.map(function (line) {
        return line.id;
      }))));
      if (line_ids) line_ids.forEach(function (id) {
        console.log("file_line_id: ", id);
        clearFileLine(id);
      });
      localStorage.removeItem("files_data");

      if (items && loaded) {
        items === null || items === void 0 ? void 0 : items.forEach(function (item) {
          return removeItem(item.variant.id);
        });
      }
    }
  }, [fileHash, window.location.hash, loaded]);

  var clearFileLine = function clearFileLine(id) {
    clearFileLineMutation({
      variables: {
        fileLineId: id
      }
    }).then(function (response) {
      var data = response.data;
      console.log("cleared File Line:", data);
    });
  };

  var paramsType = UPLOAD_TYPES.filter(function (el) {
    return el.type === fileType;
  });

  var _useState7 = useState(paramsType && paramsType.length > 0 ? paramsType[0] : UPLOAD_TYPES[5]),
      _useState8 = _slicedToArray(_useState7, 2),
      selectedUploadType = _useState8[0],
      setSelectedUploadType = _useState8[1];

  var _useState9 = useState(null),
      _useState10 = _slicedToArray(_useState9, 2),
      errors = _useState10[0],
      setErrors = _useState10[1];

  var checkFiles = function checkFiles(files) {
    files.forEach(function (file) {
      var extension = file.name.split(".").pop();
      var currentFile = filesData.find(function (data) {
        return data.file === file;
      }) || {
        file: file,
        uploadedFile: null,
        uploaded: false,
        // fileHash && fileHash.length === 32,
        mediaUrl: "/media/".concat(fileType.slice(-3), "/").concat(fileHash, "/"),
        extension: extension,
        hash: fileHash || "",
        checked: false,
        error: false
      };
      console.log("currentFile: ", currentFile);
      computeChecksumMd5(file).then(function (hash) {
        currentFile = _objectSpread({}, currentFile, {
          hash: hash,
          extension: extension
        });
        apolloClient.query({
          query: uploadedFiles,
          variables: {
            file_hashs: [hash],
            first: 2
          }
        }).then(function (_ref2) {
          var _data$uploadedFiles, _data$uploadedFiles$e;

          var data = _ref2.data;
          var url = "/media/".concat(fileType.slice(-3), "/").concat(hash, "/");
          console.log(url);
          currentFile = _objectSpread({}, currentFile, {
            uploadedFile: (_data$uploadedFiles = data.uploadedFiles) === null || _data$uploadedFiles === void 0 ? void 0 : (_data$uploadedFiles$e = _data$uploadedFiles.edges[0]) === null || _data$uploadedFiles$e === void 0 ? void 0 : _data$uploadedFiles$e.node,
            uploaded: data.uploadedFiles.totalCount > 0,
            mediaUrl: url,
            checked: true
          });
          setFilesData(function (fData) {
            return [].concat(_toConsumableArray(fData), [currentFile]);
          }); // history.push(`/upload/${selectedUploadType.type}/${hash}`);
        });
      }).catch(function (e) {
        console.log(e);
        setFilesData(function (fData) {
          return [].concat(_toConsumableArray(fData), [_objectSpread({}, currentFile, {
            error: true
          })]);
        });
      });
    });
  };

  var writeFilesData2LocalStorage = function writeFilesData2LocalStorage(fileLine, uploadedFile) {
    var files_data = JSON.parse(localStorage.getItem("files_data") || '{"files":[],"fileLines":[]}');
    var files = (files_data === null || files_data === void 0 ? void 0 : files_data.files) || [];
    var index = files.findIndex(function (el) {
      return (el === null || el === void 0 ? void 0 : el.hash) === (uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.hash);
    });
    if (index < 0) files.push(fileLine.file);else files[index] = fileLine.file;
    files_data.files = files;
    var fileLines = (files_data === null || files_data === void 0 ? void 0 : files_data.fileLines) || [];
    fileLines.push(fileLine);
    files_data.fileLines = fileLines;
    localStorage.setItem("files_data", JSON.stringify(files_data));
  };

  var createFileLine = function createFileLine() {
    var data_checkout = JSON.parse(localStorage.getItem("data_checkout") || '{"lines":[]}');
    var token = data_checkout === null || data_checkout === void 0 ? void 0 : data_checkout.token;
    filesData.forEach(function (_ref3) {
      var uploadedFile = _ref3.uploadedFile,
          hash = _ref3.hash;
      createFileLineMutation({
        variables: {
          hash: hash,
          file_line_input: {
            choosedColoredPages: uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.coloredPages,
            role: fileType,
            copies: 1,
            checkoutId: token
          }
        }
      }).then(function (response) {
        var fileLine = response.data.createFileLine.fileLine;

        if (formatVariant) {
          addItem(formatVariant.id, fileLine.copies, formatVariant.images[0], fileType, fileHash, [fileLine]);
          overlayContext.show(OverlayType.cart, OverlayTheme.right);
        }

        writeFilesData2LocalStorage(fileLine, uploadedFile);
        if (!disabledNext && !response.data.error) history.push("".concat(selectedUploadType.url).concat(filesData.map(function (_ref4) {
          var hash = _ref4.hash;
          return hash;
        }).join(",")));
      });
    });
  };

  useEffect(function () {
    if (!filesData.length) setCoverPreviewUrl("");
    filesData.forEach(function (item) {
      if (item.hash && item.checked) {
        var _item$file;

        var coverUrl = item.file.type.startsWith("image/") ? "" : generateFileThumbUrl(baseUrl, selectedUploadType.type, item.hash, (_item$file = item.file) === null || _item$file === void 0 ? void 0 : _item$file.name);
        console.log("coverUrl: ".concat(coverUrl));
        if (item.uploaded) setCoverPreviewUrl(coverUrl);else if (item.file) {
          var uploadType = selectedUploadType.uploadType;
          setFilesData(function (fData) {
            console.log("fData:", fData);
            return fData.map(function (stateItem) {
              return stateItem.hash === item.hash ? _objectSpread({}, stateItem, {
                uploaded: true
              }) : stateItem;
            });
          });
          uploadFileMutation({
            variables: {
              file: item.file,
              hash: item.hash,
              uploadType: uploadType
            }
          }).then(function (response) {
            var uploadedFile = response.data.uploadFile.uploadedFile; // TODO: delete console

            console.log("uploadFileMutation:", uploadedFile);
            setFilesData(function (fData) {
              console.log("fData:", fData);
              return fData.map(function (stateItem) {
                return stateItem.hash === item.hash ? _objectSpread({}, stateItem, {
                  uploadedFile: uploadedFile
                }) : stateItem;
              });
            });
            setCoverPreviewUrl(coverUrl);
          }).catch(function (errors) {
            setFilesData(function (fData) {
              console.log(errors);
              return fData.map(function (stateItem) {
                return stateItem.hash === item.hash ? _objectSpread({}, stateItem, {
                  uploaded: false,
                  error: true
                }) : stateItem;
              });
            });
          });
        } // console.log("selectedUploadType: ", selectedUploadType);
      }
    });
  }, [filesData.length]); // const href = fileData.uploadedFile?.url.replace("http://", "https://");

  var multifiles = ["img"].includes(fileType);
  return React.createElement("div", {
    className: "upload-page__hero",
    style: backgroundImage ? {
      backgroundImage: "url(".concat(backgroundImage.url, ")")
    } : null
  }, React.createElement("div", {
    className: "upload-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "upload-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, selectedUploadType.messages.title))), React.createElement(HelpText, null, React.createElement(FormattedMessage, selectedUploadType.messages.uploadFileDescription1), React.createElement("br", null), ((_selectedUploadType$m = selectedUploadType.messages.uploadFileDescription2) === null || _selectedUploadType$m === void 0 ? void 0 : _selectedUploadType$m.defaultMessage) && React.createElement(FormattedMessage, selectedUploadType.messages.uploadFileDescription2))), React.createElement("div", {
    className: "upload-page__hero-action"
  }, isOnline ? React.createElement("div", null, React.createElement("div", {
    className: "upload-page__upload-type"
  }, UPLOAD_TYPES.map(function (item, idx) {
    var checked = item.id === selectedUploadType.id;
    return React.createElement(Tile, {
      checked: checked,
      key: item.id,
      "data-test": "uploadTypeTile",
      "data-test-id": item.id
    }, React.createElement(Radio, {
      name: "uploadType",
      value: item.id,
      checked: checked,
      customLabel: true,
      onChange: function onChange() {
        setSelectedUploadType(item);
        history.push("/upload/".concat(item.type, "/").concat(fileHash));
      }
    }, React.createElement("span", {
      "data-test": "checkoutUploadTypeOptionName"
    }, item.name)));
  })), React.createElement("div", {
    className: "upload-page__dropzone"
  }, React.createElement(DropFile, {
    accept: selectedUploadType.mime,
    label: "Click Qua",
    errors: errors,
    setErrors: setErrors,
    type: "file",
    filesData: filesData,
    setFilesData: setFilesData,
    fileType: fileType,
    checkFiles: checkFiles,
    required: true,
    multifiles: multifiles
  }), filesData.filter(function (item) {
    return !item.uploaded;
  }).length === 0 && React.createElement("div", {
    className: "go-next__button"
  }, React.createElement(Button, {
    testingContext: "gotoNextButton",
    onClick: createFileLine,
    disabled: disabledNext
  }, React.createElement(FormattedMessage, fileType === "img" ? uploadFileMessages.stepNameCheckout : uploadFileMessages.next))), React.createElement("br", null), coverPreviewUrl && React.createElement("div", {
    className: "frontispiece-preview"
  }, React.createElement("img", {
    className: "frontispiece-img",
    src: coverPreviewUrl,
    alt: ""
  })))) : React.createElement(OfflinePlaceholder, null))));
};

export default Page;