import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  mutation UploadFileLineUpdate(\n    $id: ID!\n    $file_line_input: UploadFileLineInput!\n    $hash: String!\n  ) {\n    updateFileLine(id: $id, hash: $hash, input: $file_line_input) {\n      ok\n      fileLine {\n        id\n        hash\n        name\n        order {\n          id\n          token\n        }\n        checkout {\n          token\n        }\n        choosedColoredPages # [1, 5, 11]\n        duplex # false\n        copies # 1\n        role\n        file {\n          ...UploadedFileFragment\n        }\n        created\n        # elements {\n        #   id\n        #   variant {id, sku}\n        #   attribute {slug}\n        #   attributeValue {slug}\n        # }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  query innerPageDetails($id: ID, $slug: String, $countryCode: CountryCode) {\n    product(id: $id, slug: $slug) {\n      id\n      name\n      slug\n      descriptionJson\n      category {\n        id\n        name\n        slug\n      }\n      variants {\n        id\n        sku\n        name\n        quantityAvailable(countryCode: $countryCode)\n        images {\n          id\n          alt\n          url\n        }\n        attributes {\n          attribute {\n            id\n            name\n            slug\n            values {\n              id\n              name\n              slug\n            }\n          }\n          values {\n            id\n            name\n            slug\n          }\n        }\n        pricing {\n          price {\n            gross {\n              amount\n              currency\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query UploadedFile($hash: String, $id: ID) {\n    uploadedFile(fileHash: $hash, id: $id) {\n      ...UploadedFileFragment\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment UploadedFileFragment on UploadedFile {\n    id\n    hash\n    name\n    extension\n    pageCount\n    coloredPages\n    created\n    textAttribute {\n      id\n      name\n      slug\n      values {\n        id\n        name\n        slug\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
// TODO: Describe filePages query types
export var uploadedFileFragment = gql(_templateObject());
export var uploadedFileQuery = gql(_templateObject2(), uploadedFileFragment);
export var innerPageDetailsQuery = gql(_templateObject3());
export var fileLineUpdate = gql(_templateObject4(), uploadedFileFragment);
export var TypedInnerPageDetailsQuery = TypedQuery(innerPageDetailsQuery);