/* eslint-disable global-require */
import { COVER, PLOTTER } from "@temp/consts";
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 6;
export var SUPPORT_EMAIL = "stampa@guug.it";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  STRIPE: {
    label: "Stripe"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  },
  TAPPAY: {
    label: "Tappay",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook1",
  href: "https://www.facebook.com/guug.it/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "facebook2",
  href: "https://www.facebook.com/GUUGGERMANICO",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/super_guug/",
  path: require("../images/instagram-icon.svg")
}, {
  ariaLabel: "linkedin1",
  href: "https://www.linkedin.com/company/guug/",
  path: require("../images/linkedin-icon.svg")
}, {
  ariaLabel: "linkedin2",
  href: "https://www.linkedin.com/company/guug-srl/",
  path: require("../images/linkedin-icon.svg")
}, {
  ariaLabel: "twitter",
  href: "https://twitter.com/getsaleor",
  path: require("../images/twitter-icon.svg")
}, {
  ariaLabel: "youtube",
  href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
  path: require("../images/youtube-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Open-source PWA storefront built with Saleor's e-commerce GraphQL API. Written with React and TypeScript.",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "GuuG",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Address",
  nextActionName: "Continue to Shipping",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/shipping",
  name: "Shipping",
  nextActionName: "Continue to Payment",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  link: "/checkout/payment",
  name: "Payment",
  nextActionName: "Continue to Review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];
export var UploadFileStep;

(function (UploadFileStep) {
  UploadFileStep[UploadFileStep["Upload"] = 1] = "Upload";
  UploadFileStep[UploadFileStep["Cover"] = 2] = "Cover";
  UploadFileStep[UploadFileStep["InnerPages"] = 3] = "InnerPages";
  UploadFileStep[UploadFileStep["Checkout"] = 4] = "Checkout";
})(UploadFileStep || (UploadFileStep = {}));

export var UPLOAD_STEPS = [{
  index: 0,
  link: "/select-format/",
  name: "Select Format",
  nextActionName: "Go to Checkout",
  onlyIfCoverRequired: false,
  uploadTypes: ["img"],
  step: UploadFileStep.Upload
}, {
  index: 1,
  link: "/upload/",
  name: "Upload",
  nextActionName: "Continue",
  onlyIfCoverRequired: false,
  uploadTypes: null,
  step: UploadFileStep.Upload
}, {
  index: 2,
  link: "/tesi-product/".concat(COVER.slug, "/").concat(COVER.id, "/"),
  name: "Cover",
  nextActionName: "Continue to InnerPages",
  onlyIfCoverRequired: true,
  uploadTypes: ["thesis-pdf"],
  step: UploadFileStep.Cover
}, {
  index: 3,
  link: "/pages-preview/",
  name: "InnerPages",
  nextActionName: "Go to Checkout",
  onlyIfCoverRequired: false,
  uploadTypes: ["pdf", "thesis-pdf"],
  step: UploadFileStep.InnerPages
}, {
  index: 4,
  link: "product/".concat(PLOTTER.slug, "/").concat(PLOTTER.id, "/"),
  name: "PlotterFormat",
  nextActionName: "Go to Checkout",
  onlyIfCoverRequired: false,
  uploadTypes: ["plotter-pdf"],
  step: UploadFileStep.InnerPages
}, {
  index: 5,
  link: "/login/",
  name: "Checkout",
  nextActionName: "",
  onlyIfCoverRequired: false,
  uploadTypes: null,
  step: UploadFileStep.Checkout
}];