var slugUrl = ":slug([a-z-0-9]+)/:id([0-9]+)/";
var hashPattern = ":type([a-z-]+)?/:hash([a-f0-9,]+)?/";
var variantId = ":variantId([0-9]+)?/";
var amount = ":amount([0-9]+)?"; // :slug(amount)?/:amount([0-9]+)?";

export var baseUrl = "/";
export var searchUrl = "".concat(baseUrl, "search/");
export var categoryUrl = "".concat(baseUrl, "category/").concat(slugUrl);
export var collectionUrl = "".concat(baseUrl, "collection/").concat(slugUrl);
export var productUrl = "".concat(baseUrl, "product/").concat(slugUrl).concat(hashPattern);
export var cartUrl = "".concat(baseUrl, "cart/:token?/");
export var checkoutLoginUrl = "".concat(baseUrl, "login/");
export var pageUrl = "".concat(baseUrl, "page/:slug/");
export var guestOrderDetailsUrl = "/order-history/:token/";
export var accountUrl = "".concat(baseUrl, "account/");
export var accountConfirmUrl = "".concat(baseUrl, "account-confirm/");
export var orderHistoryUrl = "".concat(baseUrl, "order-history/");
export var addressBookUrl = "".concat(baseUrl, "address-book/");
export var passwordResetUrl = "".concat(baseUrl, "reset-password/");
export var checkoutUrl = "".concat(baseUrl, "checkout/");
export var orderFinalizedUrl = "".concat(baseUrl, "order-finalized/");
export var tesiProductUrl = "".concat(baseUrl, "tesi-product/").concat(slugUrl).concat(hashPattern);
export var pagesPreviewUrl = "".concat(baseUrl, "pages-preview/").concat(hashPattern);
export var formatUrl = "".concat(baseUrl, "select-format/").concat(hashPattern);
export var uploadBaseUrl = "".concat(baseUrl, "upload/");
export var payUrl = "".concat(baseUrl, "pay/").concat(amount);
export var uploadUrl = "".concat(uploadBaseUrl).concat(slugUrl).concat(variantId).concat(hashPattern);
export var afterPayRedirectUrl = "".concat(baseUrl, "plugins/mirumee.payments.stripe/additional-actions");