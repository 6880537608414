import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  grid-area: navigation;\n  padding-bottom: 43px;\n  height: 85px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var FileNavigation = styled.div(_templateObject());