import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 0 0.6rem 0;\n  color: blue;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  // text-transform: uppercase;\n  font-weight: 600;\n  text-decoration: underline;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 4px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Divider = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Link = styled.a(_templateObject4());
export var TextEmphasis = styled.span(_templateObject5(), function (props) {
  return props.theme.colors.bannerLink;
});
export var HelpText = styled.div(_templateObject6());