import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import "./scss/index.scss";
import { Icon } from "@components/atoms";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone"; // import { computeChecksumMd5 } from "@temp/core/utils";

var generateClassName = function generateClassName(_ref) {
  var errors = _ref.errors,
      iconLeft = _ref.iconLeft,
      styleType = _ref.styleType;
  var baseClass = "input__box";
  var errorsClass = errors && errors.length ? " input__box--error" : "";
  var iconLeftClass = iconLeft ? " input__box--left-icon" : "";
  var styleTypeClass = styleType === "grey" ? " input__box--grey" : "";
  return baseClass.concat(errorsClass, iconLeftClass, styleTypeClass);
};

var DropFile = function DropFile(_ref2) {
  var accept = _ref2.accept,
      _ref2$label = _ref2.label,
      label = _ref2$label === void 0 ? "" : _ref2$label,
      iconLeft = _ref2.iconLeft,
      iconRight = _ref2.iconRight,
      errors = _ref2.errors,
      setErrors = _ref2.setErrors,
      helpText = _ref2.helpText,
      filesData = _ref2.filesData,
      setFilesData = _ref2.setFilesData,
      fileType = _ref2.fileType,
      checkFiles = _ref2.checkFiles,
      _ref2$styleType = _ref2.styleType,
      styleType = _ref2$styleType === void 0 ? "white" : _ref2$styleType,
      _ref2$multifiles = _ref2.multifiles,
      multifiles = _ref2$multifiles === void 0 ? false : _ref2$multifiles,
      rest = _objectWithoutProperties(_ref2, ["accept", "label", "iconLeft", "iconRight", "errors", "setErrors", "helpText", "filesData", "setFilesData", "fileType", "checkFiles", "styleType", "multifiles"]);

  var maxMb = 128; // Mb

  var maxSize = maxMb * 1048576; // b = maxMb * 1024 * 1024

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      fileList = _useState2[0],
      setFileList = _useState2[1];

  var onDrop = useCallback(function (acceptedFiles) {
    // eslint-disable-next-line no-console
    console.log(acceptedFiles);

    if (multifiles) {
      setFileList(function (fileList) {
        return [].concat(_toConsumableArray(fileList), _toConsumableArray(acceptedFiles));
      });
      checkFiles(acceptedFiles);
    } else {
      setFileList([acceptedFiles[0]]);
      setFilesData([]);
      checkFiles([acceptedFiles[0]]);
    }
  }, []);

  var _useDropzone = useDropzone({
    onDrop: onDrop,
    accept: accept,
    minSize: 0,
    maxSize: maxSize
  }),
      isDragActive = _useDropzone.isDragActive,
      getRootProps = _useDropzone.getRootProps,
      getInputProps = _useDropzone.getInputProps,
      isDragReject = _useDropzone.isDragReject,
      acceptedFiles = _useDropzone.acceptedFiles,
      fileRejections = _useDropzone.fileRejections;

  var isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
  useEffect(function () {
    if (setErrors) if (fileRejections && fileRejections[0] && !isDragActive) setErrors(fileRejections[0].errors);else setErrors(null);
  }, [fileRejections, isDragActive]);

  var removeItem = function removeItem(file, e) {
    e.stopPropagation();
    if (setErrors) setErrors(null);
    setFileList(function (fileList) {
      return fileList.filter(function (item) {
        return item !== file;
      });
    });
    setFilesData(function (filesData) {
      return filesData.filter(function (item) {
        return item.file !== file;
      });
    });
  };

  return React.createElement("div", _extends({
    className: "text-center mt-5"
  }, getRootProps()), iconLeft ? React.createElement("span", {
    className: "input__icon-left"
  }, iconLeft) : null, iconRight ? React.createElement("span", {
    className: "input__icon-right"
  }, iconRight) : null, React.createElement("div", {
    className: generateClassName({
      errors: errors,
      iconLeft: iconLeft,
      styleType: styleType
    })
  }, React.createElement("input", _extends({}, getInputProps(), rest)), React.createElement("h2", null, isDragActive ? isDragReject ? "Tipo di file non accettato, spiacente!" : "Rilascia qui il file per vedere l'anteprima!" : "Clicca o trascina qua un file!"), isFileTooLarge && !isDragActive && React.createElement("div", null, React.createElement("span", {
    className: "input__error mt-2"
  }, "File \"", fileRejections[0].file.name, "\" \xE8 troppo grande,", React.createElement("br", null), "prova ottimizarlo e caricarlo di nuovo.", React.createElement("br", null), "Massimo: ", maxMb, " Mb"))), errors && React.createElement("span", {
    className: "input__error"
  }, errors.map(function (error) {
    return error.message;
  }).join(" ")), helpText && React.createElement("span", {
    className: "input__help-text"
  }, helpText), React.createElement("ul", {
    className: "list-group mt-2"
  }, fileList.length > 0 && fileList.map(function (file, idx) {
    var _fileData$uploadedFil, _fileData$uploadedFil2;

    var fileData = filesData.find(function (item) {
      return item.file === file;
    });
    return file && React.createElement("li", {
      key: "".concat(fileData === null || fileData === void 0 ? void 0 : fileData.hash, "_").concat(idx),
      className: "list-group-item list-group-item-success"
    }, React.createElement("div", {
      className: "img-preview"
    }, file.type.startsWith("image/") && React.createElement("img", {
      alt: "",
      src: URL.createObjectURL(file)
    }), file.type === "application/pdf" && React.createElement("img", {
      alt: "",
      src: "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
    }), file.type === "application/msword" && React.createElement("img", {
      alt: "",
      src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/.doc_icon.svg"
    }), file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && React.createElement("img", {
      alt: "",
      src: "https://upload.wikimedia.org/wikipedia/commons/f/fb/.docx_icon.svg"
    })), React.createElement("div", {
      className: "file-description"
    }, React.createElement("strong", null, "Name: "), React.createElement("a", {
      style: (fileData === null || fileData === void 0 ? void 0 : (_fileData$uploadedFil = fileData.uploadedFile) === null || _fileData$uploadedFil === void 0 ? void 0 : _fileData$uploadedFil.url) && {
        color: "blue"
      },
      href: fileData === null || fileData === void 0 ? void 0 : (_fileData$uploadedFil2 = fileData.uploadedFile) === null || _fileData$uploadedFil2 === void 0 ? void 0 : _fileData$uploadedFil2.url,
      rel: "noreferrer",
      target: "_blank",
      onClick: function onClick(e) {
        return e.stopPropagation();
      }
    }, file.name), React.createElement("br", null), React.createElement("strong", null, "Size: "), (file.size / 1048576).toFixed(2), " Mb", React.createElement("br", null), React.createElement("strong", null, "Type: "), " ", file.type, React.createElement("br", null), React.createElement("strong", null, "Hash: "), " ", fileData === null || fileData === void 0 ? void 0 : fileData.hash, React.createElement("br", null)), React.createElement("div", {
      className: "remove-item",
      onClick: function onClick(e) {
        return removeItem(file, e);
      }
    }, React.createElement(Icon, {
      name: "trash",
      size: 20
    })));
  }), !multifiles && acceptedFiles.length > 1 && fileList.length > 0 && React.createElement("div", null, fileType === "thesis-pdf" ? React.createElement("span", {
    className: "input__error"
  }, "Per ora puoi caricare solo un file della Tesi per volta.") : React.createElement("span", {
    className: "input__error"
  }, "Per ora accettiamo solo un file alla volta.", React.createElement("p", null, "Per caricarne altri bisogna prima completare il lavoro corrente con questo primo (nelle prossime pagine)."), "Una volta completato il lavoro, puoi caricare un altro file."))));
};

export default DropFile;