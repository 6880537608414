// import "./scss/index.scss";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { useCart } from "@saleor/sdk";
import { MONEY } from "@temp/consts";
import Page from "./Page";
import NetworkStatus from "../../components/NetworkStatus";
import { TypedMoneyQuery } from "./queries";

var createApolloClient = function createApolloClient() {
  var cache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new ApolloClient({
    ssrMode: typeof window !== "undefined",
    cache: new InMemoryCache().restore(cache),
    link: createUploadLink({
      uri: process.env.API_URI
    })
  });
};

var View = function View(_ref) {
  var amount = _ref.match.params.amount;
  var apolloClient = createApolloClient();

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      items = _useCart.items,
      totalPrice = _useCart.totalPrice,
      removeItem = _useCart.removeItem;

  return React.createElement(ApolloProvider, {
    client: apolloClient
  }, React.createElement(TypedMoneyQuery, {
    loaderFull: true,
    variables: {
      slug: MONEY.slug
    }
  }, function (_ref2) {
    var data = _ref2.data;
    return React.createElement("div", {
      className: "pages-preview-page"
    }, React.createElement(NetworkStatus, null, function (isOnline) {
      var product = data.product;
      return React.createElement("div", null, React.createElement(Page, {
        product: product,
        addToCart: addItem,
        remove: removeItem,
        items: items,
        amount: (+amount || 0) / 100,
        totalPrice: totalPrice,
        isOnline: isOnline,
        backgroundImage: {
          __typename: "Image",
          url: "https://api.guug.it/media/collection-backgrounds/artwork.png" // "https://api.guug.it/media/category-backgrounds/0.096_755x740.jpg",

        }
      }));
    }));
  }));
};

export default View;