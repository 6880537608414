import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { TaxedMoney } from "@components/containers";
import { CachedImage } from "@components/molecules";
import { COVER } from "@temp/consts";
import { generateProductUrl, generateTesiProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines,
      remove = _ref.remove;
  return React.createElement("ul", {
    className: "cart__list"
  }, lines.map(function (line, index) {
    var _line$variant$product, _line$activeFrontespi;

    var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
    var productTesiUrl = generateTesiProductUrl(line.variant.product.id, line.variant.product.name, line.fileType, line.fileHash);
    var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);
    var currentUrl = line.variant.product.name.includes(COVER.name) // || line.product.name.includes(" Page product")
    ? productTesiUrl : productUrl;
    var thumb = line.thumbnail ? line.thumbnail : (_line$variant$product = line.variant.product) === null || _line$variant$product === void 0 ? void 0 : _line$variant$product.thumbnail;
    return React.createElement("li", {
      key: key,
      className: "cart__list__item",
      "data-test": "cartRow",
      "data-test-id": line.variant.sku
    }, React.createElement(Link, {
      to: currentUrl
    }, React.createElement("div", {
      className: "cover-preview",
      style: {
        backgroundImage: "url(".concat(thumb === null || thumb === void 0 ? void 0 : thumb.url, ")")
      }
    }, line.activeFrontespiece ? React.createElement(CachedImage, {
      "data-test": "itemImage",
      alt: "text-color: ".concat((_line$activeFrontespi = line.activeFrontespiece) === null || _line$activeFrontespi === void 0 ? void 0 : _line$activeFrontespi.alt, ", \ncover: ").concat(thumb === null || thumb === void 0 ? void 0 : thumb.alt),
      url: line.activeFrontespiece.url
    }) : React.createElement(CachedImage, _extends({
      "data-test": "itemImage"
    }, thumb)))), React.createElement("div", {
      className: "cart__list__item__details"
    }, React.createElement("p", {
      "data-test": "price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: line.variant.pricing.price
    })), React.createElement(Link, {
      to: currentUrl
    }, React.createElement("p", {
      "data-test": "name"
    }, line.variant.product.name)), React.createElement("span", {
      className: "cart__list__item__details__variant"
    }, React.createElement("span", null, line.variant.name), React.createElement("span", {
      "data-test": "quantity"
    }, React.createElement(FormattedMessage, {
      id: "components.OverlayManager.Cart.ProductList.3897981513",
      defaultMessage: "Qty: {quantity}",
      values: {
        quantity: line.quantity
      }
    }))), React.createElement(ReactSVG, {
      path: removeImg,
      className: "cart__list__item__details__delete-icon",
      "data-test": "deleteButton",
      onClick: function onClick() {
        return remove(line.variant.id);
      }
    })));
  }));
};

export default ProductList;