import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";
import { commonMessages, uploadFileMessages } from "@temp/intl";
import CostRow from "./CostRow";
import FileRow from "./FileRow";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

var Table = function Table(_ref) {
  var totalCost = _ref.totalCost,
      fileLines = _ref.fileLines,
      rowProps = _objectWithoutProperties(_ref, ["totalCost", "fileLines"]);

  var intl = useIntl();
  return React.createElement(Media, {
    query: {
      minWidth: smallScreen
    }
  }, function (mediumScreen) {
    return React.createElement("table", {
      className: "cart-table"
    }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, React.createElement(FormattedMessage, uploadFileMessages.fileName)), mediumScreen && React.createElement("th", null, React.createElement(FormattedMessage, uploadFileMessages.pagesQty)), React.createElement("th", null, React.createElement(FormattedMessage, uploadFileMessages.selectedColored)), React.createElement("th", {
      className: "cart-table__quantity-header"
    }, React.createElement(FormattedMessage, commonMessages.qty)), React.createElement("th", {
      colSpan: 2
    }, React.createElement(FormattedMessage, commonMessages.price)))), React.createElement("tbody", null, fileLines.map(function (line) {
      return React.createElement(FileRow, _extends({
        key: line.id,
        fileLine: line,
        mediumScreen: mediumScreen
      }, rowProps));
    })), React.createElement("tfoot", null, totalCost && React.createElement(CostRow, {
      mediumScreen: mediumScreen,
      heading: intl.formatMessage(_defineProperty({
        defaultMessage: "Total Cost",
        "id": "components.FileTable.Table.2179891060"
      }, "id", "components.FileTable.Table.2179891060")),
      cost: totalCost
    })));
  });
};

export default Table;