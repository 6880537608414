import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "@saleor/sdk";
import { commonMessages, uploadFileMessages } from "@temp/intl";
import { COVER, INNER_PAGE } from "@temp/consts";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import { checkoutLoginUrl, checkoutUrl, uploadBaseUrl } from "../../app/routes";
import { Button, OfflinePlaceholder, TextField } from "../../components";
import { fileLineUpdate, uploadedFileQuery } from "./queries";
import { HelpText } from "./styles";
/* eslint-disable no-console */

var Page = function Page(_ref) {
  var _simplex$colored, _simplex$colored$imag, _simplex$colored2, _simplex$colored2$ima, _duplex$colored, _duplex$colored$image, _duplex$colored2, _duplex$colored2$imag;

  var add = _ref.add,
      totalPrice = _ref.totalPrice,
      product = _ref.product,
      isOnline = _ref.isOnline,
      items = _ref.items,
      fileType = _ref.fileType,
      fileHash = _ref.fileHash,
      fileLines = _ref.fileLines,
      backgroundImage = _ref.backgroundImage;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var intl = useIntl();
  var simplex_colored = product.variants.filter(function (variant) {
    return variant.sku === INNER_PAGE.colored.simplex;
  });
  var simplex_gray = product.variants.filter(function (variant) {
    return variant.sku === INNER_PAGE.grey.simplex;
  });
  var simplex = {
    colored: simplex_colored ? simplex_colored[0] : null,
    grey: simplex_gray ? simplex_gray[0] : null
  };
  var duplex_colored = product.variants.filter(function (variant) {
    return variant.sku === INNER_PAGE.colored.duplex;
  });
  var duplex_gray = product.variants.filter(function (variant) {
    return variant.sku === INNER_PAGE.grey.duplex;
  });
  var duplex = {
    colored: duplex_colored ? duplex_colored[0] : null,
    grey: duplex_gray ? duplex_gray[0] : null
  };

  var _useState = useState(simplex),
      _useState2 = _slicedToArray(_useState, 2),
      activeSides = _useState2[0],
      setActiveSides = _useState2[1];

  var apolloClient = useApolloClient();
  var baseURL = new URL("/", process.env.API_URI).href;
  useEffect(function () {
    return setPrice({
      colored: activeSides.colored.pricing.price.gross.amount,
      grey: activeSides.grey.pricing.price.gross.amount
    });
  }, [activeSides]);

  var _useMutation = useMutation(fileLineUpdate),
      _useMutation2 = _slicedToArray(_useMutation, 1),
      updateFileLineMutation = _useMutation2[0];

  var coverLine = items === null || items === void 0 ? void 0 : items.filter(function (line) {
    var _line$activeFrontespi, _line$variant;

    return ((_line$activeFrontespi = line.activeFrontespiece) === null || _line$activeFrontespi === void 0 ? void 0 : _line$activeFrontespi.id) && line.quantity > 0 && line.fileHash === fileHash || // TODO: after fix fileHash be present for logined page remove 1°:
    (line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.product.name) === COVER.name;
  });

  var _useState3 = useState((coverLine === null || coverLine === void 0 ? void 0 : coverLine.reduce(function (sum, line) {
    return sum + line.quantity;
  }, 0)) || 1),
      _useState4 = _slicedToArray(_useState3, 2),
      copies = _useState4[0],
      setCopies = _useState4[1];

  useEffect(function () {
    if (fileType === "thesis-pdf") setCopies((coverLine === null || coverLine === void 0 ? void 0 : coverLine.reduce(function (sum, line) {
      return sum + line.quantity;
    }, 0)) || 1);
  }, [coverLine]);
  var tesiCoverTotalPrice = (coverLine === null || coverLine === void 0 ? void 0 : coverLine.reduce(function (sum, line) {
    var _line$totalPrice;

    return sum + ((_line$totalPrice = line.totalPrice) === null || _line$totalPrice === void 0 ? void 0 : _line$totalPrice.gross.amount);
  }, 0)) || 0;

  var _useState5 = useState({
    colored: activeSides.colored.pricing.price.gross.amount,
    grey: activeSides.grey.pricing.price.gross.amount
  }),
      _useState6 = _slicedToArray(_useState5, 2),
      price = _useState6[0],
      setPrice = _useState6[1];

  var _useState7 = useState({
    pageQty: 0,
    coloredQty: 0,
    choosedColoredQty: 0,
    choosedColoredPages: [],
    choosedBWQty: 0,
    coloredPrice: 0,
    bWPrice: 0,
    // thumbPattern: null,
    error: false
  }),
      _useState8 = _slicedToArray(_useState7, 2),
      pagesData = _useState8[0],
      setPagesData = _useState8[1];

  var _useState9 = useState([]),
      _useState10 = _slicedToArray(_useState9, 2),
      pages = _useState10[0],
      setPages = _useState10[1];

  var _useState11 = useState(true),
      _useState12 = _slicedToArray(_useState11, 2),
      disabledCheckoutBtn = _useState12[0],
      setDisabledCheckoutBtn = _useState12[1];

  useEffect(function () {
    if (fileHash) {
      apolloClient.query({
        query: uploadedFileQuery,
        variables: {
          hash: fileHash
        }
      }).then(function (response) {
        var uploadedFile = response.data.uploadedFile;
        var coloredPages = JSON.parse(uploadedFile.coloredPages);
        console.log("uploadedFile: ", uploadedFile);
        setPagesData(_objectSpread({}, pagesData, {
          choosedColoredPages: coloredPages,
          pageQty: uploadedFile.pageCount,
          coloredQty: coloredPages.length
        }));
        setPages(Array.from({
          length: uploadedFile.pageCount
        }, function (v, i) {
          var pageN = i + 1;
          var colored = coloredPages.includes(pageN);
          return {
            id: i,
            colored: colored,
            choosedColored: colored,
            url: "media/".concat(uploadedFile.extension, "/").concat(fileHash, "/pages/page-").concat(pageN, ".png")
          };
        }));
      }).catch(function (e) {
        console.log(e);
        setPagesData(_objectSpread({}, pagesData, {
          error: true
        }));
      });
    }
  }, [fileHash]);
  useEffect(function () {
    var choosedColoredQty = pages.reduce(function (sum, page) {
      return sum + page.choosedColored;
    }, 0);
    var choosedColoredPages = [];
    pages.forEach(function (page, idx) {
      if (page.choosedColored) choosedColoredPages.push(idx + 1);
    });
    var choosedBWQty = pagesData.pageQty - choosedColoredQty;
    setPagesData(_objectSpread({}, pagesData, {
      choosedColoredQty: choosedColoredQty,
      choosedColoredPages: choosedColoredPages,
      choosedBWQty: choosedBWQty,
      coloredPrice: choosedColoredQty * price.colored,
      bWPrice: choosedBWQty * price.grey
    }));
  }, [pages]);

  var toggleColored = function toggleColored(index) {
    setPages(pages === null || pages === void 0 ? void 0 : pages.map(function (page, idx) {
      return idx === index ? _objectSpread({}, page, {
        choosedColored: page.colored && !page.choosedColored
      }) : page;
    }));
  };

  var setAllGrey = function setAllGrey() {
    setPages(pages === null || pages === void 0 ? void 0 : pages.map(function (page) {
      return _objectSpread({}, page, {
        choosedColored: false
      });
    }));
  };

  var setRestColored = function setRestColored() {
    setPages(pages === null || pages === void 0 ? void 0 : pages.map(function (page) {
      return _objectSpread({}, page, {
        choosedColored: page.colored
      });
    }));
  };

  var checkuotUrl = user ? checkoutUrl : checkoutLoginUrl;
  var uploadUrl = "".concat(uploadBaseUrl).concat(INNER_PAGE.slug, "/").concat(INNER_PAGE.id, "/").concat(fileType, "/#another");

  var addInnerPages = function addInnerPages(fileLines) {
    if (pagesData.choosedColoredQty > 0) add(activeSides.colored.id, copies * pagesData.choosedColoredQty, activeSides.colored.images[0], fileType, fileHash, fileLines);
    if (pagesData.choosedBWQty > 0) add(activeSides.grey.id, copies * pagesData.choosedBWQty, activeSides.grey.images[0], fileType, fileHash, fileLines);
  };

  var handleSubmitPages = function handleSubmitPages() {
    var files_data = JSON.parse(localStorage.getItem("files_data") || '{"files":[],"fileLines":[]}');
    var fileLines = (files_data === null || files_data === void 0 ? void 0 : files_data.fileLines) || [];
    var data_checkout = JSON.parse(localStorage.getItem("data_checkout") || '{"lines":[]}'); // TODO: Delete after adding to fileLines checkout in corect mode

    if (fileLines.length === 0) {
      var _data_checkout$lines;

      console.log("lines", data_checkout.lines);
      (_data_checkout$lines = data_checkout.lines) === null || _data_checkout$lines === void 0 ? void 0 : _data_checkout$lines.forEach(function (line) {
        var _line$fileLines;

        (_line$fileLines = line.fileLines) === null || _line$fileLines === void 0 ? void 0 : _line$fileLines.forEach(function (fileLine) {
          var index = fileLines.findIndex(function (el) {
            return el.id === fileLine.id;
          });
          if (index === -1) fileLines.push(fileLine);
        });
      });
      console.log("fileLines:", fileLines);
    }

    var token = data_checkout === null || data_checkout === void 0 ? void 0 : data_checkout.token;
    var filtered_fileLines = fileLines === null || fileLines === void 0 ? void 0 : fileLines.filter(function (line) {
      return line.hash === fileHash;
    });
    var fileLine = filtered_fileLines[0];
    fileLines.forEach(function (line) {
      var _fileLine;

      if ((line === null || line === void 0 ? void 0 : line.created) > ((_fileLine = fileLine) === null || _fileLine === void 0 ? void 0 : _fileLine.created)) fileLine = line;
    });

    if (fileLine) {
      updateFileLineMutation({
        variables: {
          id: fileLine.id,
          hash: fileHash,
          file_line_input: {
            choosedColoredPages: JSON.stringify(pagesData.choosedColoredPages),
            duplex: activeSides.colored === duplex.colored,
            role: fileType,
            copies: copies,
            checkoutId: token
          }
        }
      }).then(function (response) {
        fileLine = response.data.updateFileLine.fileLine;
        var index = fileLines.findIndex(function (el) {
          return el.id === fileLine.id;
        });
        files_data.fileLines[index] = fileLine;
        localStorage.setItem("files_data", JSON.stringify(files_data));
        addInnerPages([response.data.updateFileLine.fileLine]);
      });
      setDisabledCheckoutBtn(false);
    } else {
      // eslint-disable-next-line no-alert
      alert("fileLine is broken, please Upload file again, or write to us");
    }
  };

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseInt(evt.target.value, 10);
    setCopies(newQuantity);
  };

  var otherStyles = _objectSpread({}, backgroundImage && {
    backgroundImage: "url(".concat(backgroundImage.url, ")")
  });

  var priceTable = React.createElement("table", {
    className: "price"
  }, React.createElement("tbody", null, coverLine && coverLine.length > 0 && React.createElement("tr", null, React.createElement("th", null, intl.formatMessage(uploadFileMessages.selectedCovers), ":"), React.createElement("td", {
    colSpan: 3
  }, coverLine.length)), coverLine && coverLine.map(function (line) {
    var _line$variant2, _line$variant2$attrib, _line$totalPrice2, _line$totalPrice3;

    return line && line.variant && React.createElement("tr", {
      key: line.variant.id
    }, React.createElement("td", null, line === null || line === void 0 ? void 0 : (_line$variant2 = line.variant) === null || _line$variant2 === void 0 ? void 0 : (_line$variant2$attrib = _line$variant2.attributes[0]) === null || _line$variant2$attrib === void 0 ? void 0 : _line$variant2$attrib.values[0].name), React.createElement("td", null, line === null || line === void 0 ? void 0 : line.quantity, "x"), React.createElement("td", null, line === null || line === void 0 ? void 0 : (_line$totalPrice2 = line.totalPrice) === null || _line$totalPrice2 === void 0 ? void 0 : _line$totalPrice2.gross.amount.toFixed(2)), React.createElement("td", null, line === null || line === void 0 ? void 0 : (_line$totalPrice3 = line.totalPrice) === null || _line$totalPrice3 === void 0 ? void 0 : _line$totalPrice3.gross.currency));
  }), React.createElement("tr", null, React.createElement("th", {
    colSpan: 4
  }, intl.formatMessage(uploadFileMessages.innerPages), React.createElement("span", {
    style: {
      color: "Black"
    }
  }, copies), copies > 1 ? intl.formatMessage(uploadFileMessages.copies) : intl.formatMessage(uploadFileMessages.copy), "):")), React.createElement("tr", null, React.createElement("td", null, intl.formatMessage(uploadFileMessages.colored)), React.createElement("td", null, copies, " x ", pagesData.choosedColoredQty, "p."), React.createElement("td", null, (pagesData.coloredPrice * copies).toFixed(2)), React.createElement("td", null, " ", totalPrice && totalPrice.gross.currency)), React.createElement("tr", null, React.createElement("td", null, intl.formatMessage(uploadFileMessages.blackWhite)), React.createElement("td", null, copies, " x ", pagesData.choosedBWQty, "p."), React.createElement("td", null, (pagesData.bWPrice * copies).toFixed(2)), React.createElement("td", null, " ", totalPrice && totalPrice.gross.currency)), React.createElement("tr", null, React.createElement("th", null, intl.formatMessage(uploadFileMessages.filePrintTotal), ":"), React.createElement("td", null, copies, "\xA0", copies > 1 ? intl.formatMessage(uploadFileMessages.copies) : intl.formatMessage(uploadFileMessages.copy)), React.createElement("td", null, ((pagesData.coloredPrice + pagesData.bWPrice) * copies + tesiCoverTotalPrice).toFixed(2)), React.createElement("td", null, totalPrice && totalPrice.gross.currency)), React.createElement("tr", null, React.createElement("th", null, intl.formatMessage(commonMessages.totalPrice), ":"), React.createElement("td", null), React.createElement("td", null, ((totalPrice ? totalPrice.gross.amount : 0) + (disabledCheckoutBtn && (pagesData.coloredPrice + pagesData.bWPrice) * copies)).toFixed(2)), React.createElement("td", null, totalPrice && totalPrice.gross.currency))));
  var buttons = React.createElement("div", {
    className: "pages_preview-buttons"
  }, React.createElement("div", {
    className: "fronte-retro"
  }, React.createElement("div", {
    className: activeSides.colored === simplex.colored ? "active" : "",
    onClick: function onClick(e) {
      return setActiveSides(simplex);
    }
  }, React.createElement("span", {
    title: "Solo Fronte"
  }, "Solo Fronte"), React.createElement("img", {
    src: (_simplex$colored = simplex.colored) === null || _simplex$colored === void 0 ? void 0 : (_simplex$colored$imag = _simplex$colored.images[0]) === null || _simplex$colored$imag === void 0 ? void 0 : _simplex$colored$imag.url,
    alt: (_simplex$colored2 = simplex.colored) === null || _simplex$colored2 === void 0 ? void 0 : (_simplex$colored2$ima = _simplex$colored2.images[0]) === null || _simplex$colored2$ima === void 0 ? void 0 : _simplex$colored2$ima.alt,
    title: "Solo Fronte"
  })), React.createElement("div", {
    className: activeSides.colored === duplex.colored ? "active" : "",
    onClick: function onClick(e) {
      return setActiveSides(duplex);
    }
  }, React.createElement("span", {
    title: "Fronte/Retro"
  }, "Fronte/Retro"), React.createElement("img", {
    src: (_duplex$colored = duplex.colored) === null || _duplex$colored === void 0 ? void 0 : (_duplex$colored$image = _duplex$colored.images[0]) === null || _duplex$colored$image === void 0 ? void 0 : _duplex$colored$image.url,
    alt: (_duplex$colored2 = duplex.colored) === null || _duplex$colored2 === void 0 ? void 0 : (_duplex$colored2$imag = _duplex$colored2.images[0]) === null || _duplex$colored2$imag === void 0 ? void 0 : _duplex$colored2$imag.alt,
    title: "Fronte/Retro"
  }))), fileType !== "thesis-pdf" && React.createElement(TextField, {
    name: "quantity",
    type: "number",
    label: copies > 1 ? intl.formatMessage(uploadFileMessages.copies) : intl.formatMessage(uploadFileMessages.copy),
    min: "1",
    max: Math.min(1000 / (pagesData.choosedBWQty || 1), 1000 / (pagesData.coloredQty || 1)),
    value: copies.toString(),
    disabled: false,
    onChange: handleQuantityChange // errors={[{ message: "seresr" }]}

  }), React.createElement("div", {
    className: "colored-gray"
  }, React.createElement(Button, {
    testingContext: "setAllGrey",
    onClick: setAllGrey
  }, React.createElement(FormattedMessage, uploadFileMessages.setAllGrey)), "  ", React.createElement(Button, {
    testingContext: "setRestColored",
    onClick: setRestColored
  }, React.createElement(FormattedMessage, uploadFileMessages.setRestColored))), "\xA0", React.createElement("div", {
    className: "checkout"
  }, fileType !== "thesis-pdf" && React.createElement(Link, {
    to: uploadUrl
  }, React.createElement(Button, {
    onClick: handleSubmitPages,
    secondary: true,
    testingContext: "gotoCheckoutButton"
  }, React.createElement(FormattedMessage, uploadFileMessages.uploadAnotherFile))), "\xA0", React.createElement(Link, {
    to: checkuotUrl
  }, React.createElement(Button, {
    onClick: handleSubmitPages,
    secondary: true,
    testingContext: "gotoCheckoutButton"
  }, React.createElement(FormattedMessage, commonMessages.checkout)))));
  var pagesGallery = React.createElement("div", null, isOnline ? React.createElement("div", null, React.createElement("div", {
    className: "page-selector"
  }, pages && pages.map(function (item, i) {
    return React.createElement("div", {
      // TODO: replace to valid key
      key: i,
      onClick: function onClick() {
        return toggleColored(i);
      },
      className: "page-selector__item".concat(!item.colored ? " page-selector__item--bw-disabled" : "").concat(!item.choosedColored ? " page-selector__item--bw" : ""),
      "data-page-idx": i
    }, React.createElement("img", {
      src: baseURL + item.url,
      alt: ""
    }));
  }))) : React.createElement(OfflinePlaceholder, null));
  return React.createElement("div", {
    className: "pages-preview__hero",
    style: _objectSpread({
      height: "auto"
    }, otherStyles)
  }, React.createElement("div", {
    className: "pages-preview__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "pages-preview__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, uploadFileMessages.selectPages))), React.createElement(HelpText, null, React.createElement(FormattedMessage, uploadFileMessages.pagesPreviewDescription1), React.createElement("br", null), React.createElement(FormattedMessage, uploadFileMessages.pagesPreviewDescription2), React.createElement("br", null), React.createElement(FormattedMessage, uploadFileMessages.pagesPreviewDescription3))), React.createElement("div", {
    className: "pages-preview__container"
  }, React.createElement(Media, {
    query: {
      maxWidth: mediumScreen
    }
  }, function (matches) {
    return matches ? React.createElement("div", {
      className: "pages-preview__medium"
    }, React.createElement("div", {
      className: "pages-preview-page__pages-preview__info"
    }, React.createElement("div", {
      className: classNames("pages-preview-page__pages-preview__info--fixed")
    }, priceTable, buttons)), pagesGallery) : React.createElement("div", {
      className: "pages-preview__large"
    }, React.createElement("div", {
      className: "pages-preview-page__pages-preview__gallery" // ref={productGallery}

    }, pagesGallery), React.createElement("div", {
      className: "pages-preview-page__pages-preview__info"
    }, React.createElement("div", {
      className: classNames("pages-preview-page__pages-preview__info--fixed")
    }, priceTable, buttons)));
  }))));
};

export default Page;