import React from "react";
import * as S from "./styles";
export var ErrorMessage = function ErrorMessage(_ref) {
  var errors = _ref.errors;
  return errors && errors.length ? React.createElement(S.ErrorMessage, {
    "data-test": "errorMessage"
  }, errors.map(function (error, index) {
    var linkPos = error.message.indexOf("https://");
    var link = linkPos > 0 ? error.message.slice(linkPos) : "";
    var emailPos = error.message.indexOf("mailto:");
    var email = emailPos > 0 ? error.message.slice(emailPos) : "";
    return React.createElement(S.ErrorParagraph, {
      key: index,
      "data-test-id": index
    }, linkPos > 0 ? error.message.slice(0, linkPos) : emailPos > 0 ? error.message.slice(0, emailPos) : error.message, link && React.createElement(S.Link, {
      href: link
    }, link), email && React.createElement(S.Link, {
      href: email
    }, email.slice(7)));
  })) : null;
};