import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n\n  & > iframe {\n    display: flex;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.3rem;\n  margin-bottom: 2rem;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem;\n  box-shadow: 0px 23px 18px -18px rgba(30, 70, 79, 0.7);\n  border: 2px solid #1e464f50;\n  border-radius: 1rem;\n  margin-top: 2rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.6rem 0 1.4rem 0;\n  font-size: ", ";\n  color: rgba(50, 50, 50, 0.6);\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Grid = styled.div(_templateObject2(), media.smallScreen(_templateObject3()));
export var Divider = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var SubTitle = styled.h4(_templateObject6(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var TextSummary = styled.p(_templateObject7(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var ErrorMessages = styled.div(_templateObject8());
export var NoteSection = styled.section(_templateObject9());
export var IframeBox = styled.div(_templateObject10());
export var Info3D = styled.span(_templateObject11());
export var IframeContainer = styled.div(_templateObject12());