import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { Input } from "@components/atoms";
import * as S from "./styles";
export var TesiProductVariantPicker = function TesiProductVariantPicker(_ref) {
  var _ref$productVariants = _ref.productVariants,
      productVariants = _ref$productVariants === void 0 ? [] : _ref$productVariants,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      onChange = _ref.onChange,
      activeFrontespiece = _ref.activeFrontespiece,
      activeProductImage = _ref.activeProductImage;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      selectedVariant = _useState2[0],
      setSelectedVariant = _useState2[1];

  useEffect(function () {
    if (!productVariants || !activeProductImage || !activeFrontespiece) return;
    var activeProductVariant = productVariants.find(function (el) {
      var _el$images$, _el$attributes$2$valu;

      return el && el.images && ((_el$images$ = el.images[0]) === null || _el$images$ === void 0 ? void 0 : _el$images$.url) === activeProductImage.url && activeFrontespiece.alt === ((_el$attributes$2$valu = el.attributes[2].values[0]) === null || _el$attributes$2$valu === void 0 ? void 0 : _el$attributes$2$valu.name);
    });
    setSelectedVariant(activeProductVariant);
    var coverType = activeProductVariant === null || activeProductVariant === void 0 ? void 0 : activeProductVariant.attributes[0];
    var coverColor = activeProductVariant === null || activeProductVariant === void 0 ? void 0 : activeProductVariant.attributes[1];
    var textColor = activeProductVariant === null || activeProductVariant === void 0 ? void 0 : activeProductVariant.attributes[2];

    if (textColor) {
      onAttributeChangeHandler(textColor.attribute.slug, activeFrontespiece.alt);
    }

    if (coverType) {
      var _coverType$values$;

      onAttributeChangeHandler(coverType.attribute.slug, ((_coverType$values$ = coverType.values[0]) === null || _coverType$values$ === void 0 ? void 0 : _coverType$values$.name) || "");
    }

    if (coverColor) {
      var _coverColor$values$;

      onAttributeChangeHandler(coverColor.attribute.slug, ((_coverColor$values$ = coverColor.values[0]) === null || _coverColor$values$ === void 0 ? void 0 : _coverColor$values$.name) || "");
    }

    if (onChange) {
      onChange(activeProductVariant);
    }
  }, [activeProductImage, activeFrontespiece]);
  return React.createElement(S.Wrapper, null, selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.attributes.map(function (el) {
    var attribute = el.attribute,
        values = el.values;
    var value = values[0];
    return React.createElement(Input, {
      key: attribute.id,
      label: attribute.name || "",
      value: "".concat(value === null || value === void 0 ? void 0 : value.name),
      readOnly: true,
      name: attribute.slug || ""
    });
  }));
};