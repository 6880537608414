import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React, { useState } from "react";
import "./scss/style.scss";
export var TesiColorPreview = function TesiColorPreview(_ref) {
  var frontespieces = _ref.frontespieces,
      active = _ref.active,
      props = _objectWithoutProperties(_ref, ["frontespieces", "active"]);

  var _useState = useState(active.item),
      _useState2 = _slicedToArray(_useState, 2),
      activeEl = _useState2[0],
      setActiveEl = _useState2[1];

  return React.createElement("div", {
    className: "color-preview pulsable"
  }, React.createElement("style", {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html: frontespieces.map(function (el) {
        return ".color-preview__item[data-color=\"".concat(el.hexColor, "\"] {\n                  color: ").concat(el.hexColor, ";\n                  border-color: ").concat(el.hexColor, ";\n                }\n                .color-preview__item[data-color=\"").concat(el.hexColor, "\"]:hover,\n                .color-preview__item--active[data-color=\"").concat(el.hexColor, "\"] {\n                  background-color: ").concat(el.hexColor, ";\n                }\n            ");
      }).join("\n")
    }
  }), frontespieces && frontespieces.map(function (el, index) {
    return React.createElement("span", {
      key: el.id,
      className: "color-preview__item".concat(active.item.id === el.id ? " color-preview__item--active" : "").concat(el.id === activeEl.id ? " click" : ""),
      onClick: function onClick() {
        setActiveEl(el);
        active.set(el);
      },
      onMouseEnter: function onMouseEnter() {
        return active.set(el);
      },
      onMouseLeave: function onMouseLeave() {
        if (active.item !== activeEl) active.set(activeEl);
      },
      "data-color": el.hexColor
    }, el.alt);
  }));
};