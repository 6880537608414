import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query moneyDetails($id: ID, $slug: String, $countryCode: CountryCode) {\n    product(id: $id, slug: $slug) {\n      id\n      name\n      slug\n      descriptionJson\n      category {\n        id\n        name\n        slug\n      }\n      variants {\n        id\n        sku\n        name\n        quantityAvailable(countryCode: $countryCode)\n        images {\n          id\n          alt\n          url\n        }\n        attributes {\n          attribute {\n            id\n            name\n            slug\n            values {\n              id\n              name\n              slug\n            }\n          }\n          values {\n            id\n            name\n            slug\n          }\n        }\n        pricing {\n          price {\n            gross {\n              amount\n              currency\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var moneyDetailsQuery = gql(_templateObject());
export var TypedMoneyQuery = TypedQuery(moneyDetailsQuery);