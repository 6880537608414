import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { uploadFileMessages } from "@temp/intl";
import * as S from "./styles";
var activeDot = React.createElement(S.ActiveDot, null, React.createElement(S.Dot, {
  done: true
}));
var doneDot = React.createElement(S.Dot, {
  done: true
});
var inactiveDot = React.createElement(S.Dot, null);

var generateDot = function generateDot(stepIndex, currentActiveStep) {
  if (stepIndex < currentActiveStep) {
    return doneDot;
  }

  if (stepIndex === currentActiveStep) {
    return activeDot;
  }

  if (stepIndex > currentActiveStep) {
    return inactiveDot;
  }
};

var generateLabel = function generateLabel(stepIndex, name, numberOfSteps) {
  if (stepIndex === 0) {
    return React.createElement(S.LeftLabel, null, name);
  }

  if (stepIndex === numberOfSteps - 1) {
    return React.createElement(S.RightLabel, null, name);
  }

  return React.createElement(S.Label, null, name);
};

var generateProgressBar = function generateProgressBar(index, currentActive, numberOfSteps) {
  if (index !== numberOfSteps - 1) {
    return React.createElement(S.ProgressBar, {
      done: currentActive > index
    });
  }
};

var generateSteps = function generateSteps(steps, currentActive, intl, type, hash) {
  return steps === null || steps === void 0 ? void 0 : steps.map(function (step, index) {
    var name = step.name;
    /* eslint-disable default-case */

    switch (step.name) {
      case "Upload":
        name = intl.formatMessage(uploadFileMessages.stepNameUpload);
        break;

      case "Cover":
        name = intl.formatMessage(uploadFileMessages.stepNameCover);
        break;

      case "InnerPages":
        name = intl.formatMessage(uploadFileMessages.stepNameInnerPages);
        break;

      case "PlotterFormat":
        name = intl.formatMessage(uploadFileMessages.stepNamePlotterFormat);
        break;

      case "Checkout":
        name = intl.formatMessage(uploadFileMessages.stepNameCheckout);
        break;
    }

    return React.createElement(S.Step, {
      key: step.index
    }, step.index < currentActive ? React.createElement(Link, {
      to: "".concat(step.link).concat(type, "/").concat(hash, "/")
    }, generateDot(index, currentActive), generateLabel(index, name, steps.length)) : React.createElement(React.Fragment, null, generateDot(index, currentActive), generateLabel(index, name, steps.length)), generateProgressBar(index, currentActive, steps.length));
  });
};
/**
 * Progress bar showing current step of checkout process.
 */


var FileProgressBar = function FileProgressBar(_ref) {
  var steps = _ref.steps,
      activeStep = _ref.activeStep,
      type = _ref.type,
      hash = _ref.hash;
  var intl = useIntl();
  var filteredSteps = steps.filter(function (_ref2) {
    var uploadTypes = _ref2.uploadTypes;
    return !uploadTypes || uploadTypes.includes(type);
  });
  return React.createElement(S.Wrapper, null, generateSteps(filteredSteps, activeStep, intl, type, hash));
};

export { FileProgressBar };