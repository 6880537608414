import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 4px 5px;\n  background-color: rgba(255, 255, 255, 0.7);\n  max-width: 840px;\n  width: fit-content;\n  margin: 0 auto 8px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  padding-bottom: 43px;\n  height: 85px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var FileNavigation = styled.div(_templateObject());
export var HelpText = styled.div(_templateObject2());