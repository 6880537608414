import "./scss/index.scss";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { useCart } from "@saleor/sdk";
import { FileProgressBar } from "@components/molecules/FileProgressBar";
import { UPLOAD_STEPS } from "@temp/core/config";
import { INNER_PAGE } from "@temp/consts";
import Page from "./Page";
import NetworkStatus from "../../components/NetworkStatus";
import { TypedInnerPageDetailsQuery } from "./queries";
import { FileNavigation } from "./styles";

var createApolloClient = function createApolloClient() {
  var cache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new ApolloClient({
    ssrMode: typeof window !== "undefined",
    cache: new InMemoryCache().restore(cache),
    link: createUploadLink({
      uri: process.env.API_URI
    })
  });
};

var View = function View(_ref) {
  var match = _ref.match;
  var apolloClient = createApolloClient();

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      items = _useCart.items,
      totalPrice = _useCart.totalPrice;

  return React.createElement(ApolloProvider, {
    client: apolloClient
  }, React.createElement(TypedInnerPageDetailsQuery, {
    loaderFull: true,
    variables: {
      slug: INNER_PAGE.slug
    }
  }, function (_ref2) {
    var data = _ref2.data;
    return React.createElement("div", {
      className: "pages-preview-page"
    }, React.createElement(NetworkStatus, null, function (isOnline) {
      var product = data.product;
      return React.createElement("div", null, React.createElement("div", {
        className: "upload-page__navigation"
      }, React.createElement(FileNavigation, null, React.createElement(FileProgressBar, {
        steps: UPLOAD_STEPS,
        activeStep: match.params.type === "thesis-pdf" ? 2 : 1,
        type: match.params.type,
        hash: match.params.hash
      }))), React.createElement(Page, {
        product: product,
        add: addItem,
        items: items,
        totalPrice: totalPrice,
        isOnline: isOnline,
        fileType: match.params.type,
        fileHash: match.params.hash,
        backgroundImage: {
          __typename: "Image",
          url: "https://api.guug.it/media/collection-backgrounds/artwork.png" // "https://api.guug.it/media/category-backgrounds/0.096_755x740.jpg",

        }
      }));
    }));
  }));
};

export default View;