import classNames from "classnames";
import * as React from "react"; // import { TaxedMoney } from "@components/containers";

import { Thumbnail } from "@components/molecules"; // import { OrderByToken_orderByToken_lines_unitPrice } from "@saleor/sdk/lib/queries/gqlTypes/OrderByToken";

var FileRow = function FileRow(_ref) {
  var mediumScreen = _ref.mediumScreen,
      processing = _ref.processing,
      fileLine = _ref.fileLine;
  return React.createElement("tr", {
    className: classNames({
      "cart-table-row--processing": processing
    })
  }, React.createElement("td", {
    className: "cart-table__thumbnail"
  }, React.createElement("div", null, mediumScreen && React.createElement("a", {
    href: fileLine.file.url
  }, React.createElement(Thumbnail, {
    source: {
      thumbnail: {
        url: fileLine.file.thumbnail,
        alt: "incisione",
        __typename: "Image"
      },
      thumbnail2x: {
        url: fileLine.file.thumbnail,
        __typename: "Image"
      }
    }
  })), React.createElement("a", {
    href: fileLine.file.url
  }, fileLine.name))), mediumScreen && React.createElement("td", null, fileLine.file.pageCount), React.createElement("td", null, fileLine.choosedColoredPages), React.createElement("td", {
    className: "cart-table__quantity-cell"
  }, React.createElement("p", null, fileLine.copies)), React.createElement("td", {
    colSpan: 2
  }));
};

export default FileRow;