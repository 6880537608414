import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Loader } from "@components/atoms";
import { ProductTile } from "@components/molecules";
import { COVER } from "@temp/consts";
import { generateProductUrl, generateTesiProductUrl } from "../../../../core/utils";
import * as S from "./styles";

var currentUrl = function currentUrl(id, name, fileType, fileHash) {
  return name.includes(COVER.name) // || productName.includes(" Page product")
  ? generateTesiProductUrl(id, name, fileType, fileHash) : generateProductUrl(id, name);
};

export var ProductList = function ProductList(_ref) {
  var products = _ref.products,
      _ref$canLoadMore = _ref.canLoadMore,
      canLoadMore = _ref$canLoadMore === void 0 ? false : _ref$canLoadMore,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      testingContextId = _ref.testingContextId,
      _ref$onLoadMore = _ref.onLoadMore,
      onLoadMore = _ref$onLoadMore === void 0 ? function () {
    return null;
  } : _ref$onLoadMore,
      fileType = _ref.fileType,
      fileHash = _ref.fileHash;
  return React.createElement(React.Fragment, null, React.createElement(S.List, {
    "data-test": "productList",
    "data-test-id": testingContextId
  }, products.map(function (product) {
    var id = product.id,
        name = product.name;
    return id && name && React.createElement(Link, {
      to: currentUrl(id, name, fileType, fileHash),
      key: id
    }, React.createElement(ProductTile, {
      product: product
    }));
  })), React.createElement(S.Loader, null, loading ? React.createElement(Loader, null) : canLoadMore && React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    color: "secondary",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ProductList.ProductList.2783099424",
    defaultMessage: "More +"
  }))));
};