import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import { uploadFileMessages } from "@temp/intl";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import { Breadcrumbs, Button, TesiProductDescription } from "../../components";
import { generateCategoryUrl, generateProductUrl, generateTesiProductUrl } from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
import { TesiProductDescription as NewProductDescription, TesiColorPreview } from "../../@next/components/molecules";
import { TesiProductGallery } from "../../@next/components/organisms";
import { structuredData } from "../../core/SEO/Product/structuredData";
import { COVER, INCISIONE, UPLOAD_TYPES } from "../../consts";

var populateBreadcrumbs = function populateBreadcrumbs(product, fileType, fileHash) {
  return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: product.name.includes(COVER.name) ? generateTesiProductUrl(product.id, product.name, fileType, fileHash) : generateProductUrl(product.id, product.name),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var _UPLOAD_TYPES$filter;

  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      fileType = _ref.fileType,
      fileHash = _ref.fileHash,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var tesiProductGallery = React.useRef();
  var images = getImages();
  var frontispieces = getFrontispieces();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var _React$useState3 = React.useState(true),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      disabledNext = _React$useState4[0],
      setDisabledNext = _React$useState4[1];

  var _React$useState5 = React.useState(images[0]),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      activeProductImage = _React$useState6[0],
      setActiveProductImage = _React$useState6[1];

  var _React$useState7 = React.useState(frontispieces.find(function (el) {
    return el.hexColor === "#cda434";
  })),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      activeFrontespiece = _React$useState8[0],
      setActiveFrontespiece = _React$useState8[1];

  React.useEffect(function () {
    var ids = images.map(function (el) {
      return el.id;
    });

    if (activeProductImage && !ids.includes(activeProductImage.id)) {
      setActiveProductImage(images[0]);
    }
  }, [images]);

  function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.filter(function (variant) {
        return variant.id === variantId;
      }).pop();

      if (variant.images.length > 0) {
        return variant.images;
      }

      return product.images;
    }

    return product.images;
  }

  function getFrontispieces() {
    if (product.attributes) {
      var attribute = product.attributes.filter(function (el) {
        return el.attribute.slug === INCISIONE.slug;
      }).pop(); // TODO: use uploadedFileRootUrl

      var baseUrl = new URL("/", process.env.API_URI).origin;

      var _frontispieces = attribute.values.map(function (attr) {
        return {
          id: attr.id,
          alt: attr.name,
          hexColor: "#".concat(attr.slug),
          url: "".concat(baseUrl, "/media/pdf/").concat(fileHash, "/covers/cover-").concat(encodeURI(attr.slug), ".png")
        };
      });

      return _frontispieces;
    }

    return [];
  }

  var tesiProductDescription = React.createElement("div", null, React.createElement(TesiProductDescription, {
    items: items,
    productId: product.id,
    name: product.name,
    productVariants: product.variants,
    pricing: product.pricing,
    queryAttributes: queryAttributes,
    addToCart: add,
    setVariantId: setVariantId,
    fileType: fileType,
    fileHash: fileHash,
    activeFrontespiece: activeFrontespiece,
    activeProductImage: activeProductImage,
    onAttributeChangeHandler: onAttributeChangeHandler,
    setDisabledNext: setDisabledNext
  }), React.createElement("div", {
    className: "cart__footer__button"
  }, !disabledNext && React.createElement(Link, {
    to: ((_UPLOAD_TYPES$filter = UPLOAD_TYPES.filter(function (step) {
      return step.type === "pdf";
    })) === null || _UPLOAD_TYPES$filter === void 0 ? void 0 : _UPLOAD_TYPES$filter.pop().url.replace("/pdf/", "/".concat(fileType, "/"))) + fileHash
  }, React.createElement(Button, {
    testingContext: "gotoCheckoutButton"
  }, React.createElement(FormattedMessage, uploadFileMessages.next)))));
  return React.createElement("div", {
    className: "product-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product, fileType, fileHash)
  })), React.createElement("div", {
    className: "container"
  }, activeFrontespiece && React.createElement(TesiColorPreview, {
    frontespieces: frontispieces,
    active: {
      item: activeFrontespiece,
      set: setActiveFrontespiece
    }
  }), React.createElement("div", {
    className: "product-page__product"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(product)), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(GalleryCarousel, {
      images: images,
      activeFrontespiece: activeFrontespiece,
      activeProductImage: {
        item: activeProductImage,
        set: setActiveProductImage
      }
    }), React.createElement("div", {
      className: "product-page__product__info"
    }, tesiProductDescription)) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery",
      ref: tesiProductGallery
    }, React.createElement(TesiProductGallery, {
      images: images,
      activeFrontespiece: activeFrontespiece,
      activeProductImage: {
        item: activeProductImage,
        set: setActiveProductImage
      }
    })), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, tesiProductDescription)));
  }))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product__description"
  }, React.createElement(NewProductDescription, {
    descriptionJson: product.descriptionJson,
    attributes: product.attributes
  }))));
};

export default Page;