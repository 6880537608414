import "./scss/index.scss";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { FileProgressBar } from "@components/molecules/FileProgressBar";
import { UPLOAD_STEPS } from "@temp/core/config";
import { PHOTO } from "@temp/consts";
import Page from "./Page";
import NetworkStatus from "../../components/NetworkStatus";
import { TypedFormatDetailsQuery } from "./queries";
import { FileNavigation } from "./styles";

var createApolloClient = function createApolloClient() {
  var cache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new ApolloClient({
    ssrMode: typeof window !== "undefined",
    cache: new InMemoryCache().restore(cache),
    link: createUploadLink({
      uri: process.env.API_URI
    })
  });
};

var View = function View(_ref) {
  var match = _ref.match;
  var apolloClient = createApolloClient();
  return React.createElement(ApolloProvider, {
    client: apolloClient
  }, React.createElement(TypedFormatDetailsQuery, {
    loaderFull: true,
    variables: {
      slug: PHOTO.slug
    }
  }, function (response) {
    return React.createElement("div", {
      className: "pages-preview-page"
    }, React.createElement(NetworkStatus, null, function (isOnline) {
      return React.createElement("div", null, React.createElement("div", {
        className: "upload-page__navigation"
      }, React.createElement(FileNavigation, null, React.createElement(FileProgressBar, {
        steps: UPLOAD_STEPS,
        activeStep: 0,
        type: match.params.type,
        hash: match.params.hash
      }))), React.createElement(Page, {
        product: response.data.product,
        isOnline: isOnline,
        fileType: match.params.type,
        fileHash: match.params.hash,
        backgroundImage: {
          __typename: "Image",
          url: "https://api.guug.it/media/collection-backgrounds/artwork.png" // "https://api.guug.it/media/category-backgrounds/0.096_755x740.jpg",

        }
      }));
    }));
  }));
};

export default View;