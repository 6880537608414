import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { CachedImage } from "@components/molecules";
import { Carousel } from "../../components";
import noPhotoImg from "../../images/no-photo.svg";

var GalleryCarousel = function GalleryCarousel(_ref) {
  var _activeProductImage$i;

  var images = _ref.images,
      activeFrontespiece = _ref.activeFrontespiece,
      activeProductImage = _ref.activeProductImage;

  var _React$useState = React.useState(images.map(function (el) {
    return el.id;
  }).indexOf((_activeProductImage$i = activeProductImage.item) === null || _activeProductImage$i === void 0 ? void 0 : _activeProductImage$i.id)),
      _React$useState2 = _slicedToArray(_React$useState, 1),
      initialIndex = _React$useState2[0];

  return React.createElement("div", {
    className: "product-page__product__gallery"
  }, React.createElement(Carousel, {
    afterSlide: function afterSlide(slideIndex) {
      return activeProductImage.set(images[slideIndex]);
    },
    slideIndex: initialIndex,
    renderCenterLeftControls: function renderCenterLeftControls() {
      return null;
    },
    renderCenterRightControls: function renderCenterRightControls() {
      return null;
    },
    renderBottomCenterControls: function renderBottomCenterControls(props) {
      return React.createElement("ul", {
        className: "product-page__product__gallery__nav"
      }, images.map(function (image, index) {
        return React.createElement("li", {
          key: image.id,
          onClick: props.goToSlide.bind(null, index),
          className: props.currentSlide === index ? "active" : ""
        }, React.createElement("span", null));
      }));
    }
  }, images.map(function (image) {
    var alt = "text-color: ".concat(activeFrontespiece === null || activeFrontespiece === void 0 ? void 0 : activeFrontespiece.alt, ", \ncover: ").concat(image === null || image === void 0 ? void 0 : image.alt);
    return React.createElement("div", {
      key: image.id,
      className: "cover-preview",
      style: {
        backgroundImage: "url(".concat(image.url, ")")
      }
    }, React.createElement(CachedImage, {
      alt: alt,
      url: (activeFrontespiece === null || activeFrontespiece === void 0 ? void 0 : activeFrontespiece.url) || noPhotoImg
    }, React.createElement("img", {
      src: noPhotoImg,
      title: alt,
      alt: alt
    })));
  })));
};

export default GalleryCarousel;