import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import ReactSelect from "react-select";
import { ThemeContext } from "styled-components";
import { ErrorMessage } from "../ErrorMessage";
import * as S from "./styles";

var optionStyle = function optionStyle(customTheme) {
  return {
    option: function option(provided, state) {
      return _objectSpread({}, provided, {
        alignItems: "center",
        backgroundColor: state.isSelected ? customTheme.colors.primaryLight : state.isFocused ? customTheme.colors.primaryTransparent : "white",
        color: state.isDisabled ? customTheme.colors.lightFont : customTheme.colors.dark,
        display: "flex",
        fontWeight: state.isSelected && customTheme.typography.boldFontWeight,
        margin: "0 auto",
        minHeight: "34px",
        verticalAlign: "middle",
        width: "95%"
      });
    }
  };
};

export var Select = function Select(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      clearable = _ref.clearable,
      clearValue = _ref.clearValue,
      name = _ref.name,
      options = _ref.options,
      isOptionDisabled = _ref.isOptionDisabled,
      customComponents = _ref.customComponents,
      defaultValue = _ref.defaultValue,
      menuIsOpen = _ref.menuIsOpen,
      customStyles = _ref.customStyles,
      _ref$optionLabelKey = _ref.optionLabelKey,
      optionLabelKey = _ref$optionLabelKey === void 0 ? "label" : _ref$optionLabelKey,
      _ref$optionValueKey = _ref.optionValueKey,
      optionValueKey = _ref$optionValueKey === void 0 ? "value" : _ref$optionValueKey,
      errors = _ref.errors,
      props = _objectWithoutProperties(_ref, ["value", "onChange", "clearable", "clearValue", "name", "options", "isOptionDisabled", "customComponents", "defaultValue", "menuIsOpen", "customStyles", "optionLabelKey", "optionValueKey", "errors"]);

  var customTheme = React.useContext(ThemeContext);

  var handleChange = function handleChange(value) {
    if (onChange) {
      if (name) onChange(value, name);else onChange(value);
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(ReactSelect, _extends({
    defaultValue: defaultValue,
    onChange: handleChange,
    value: value,
    clearValue: clearValue,
    menuIsOpen: menuIsOpen,
    menuShouldScrollIntoView: true,
    tabSelectsValue: false,
    getOptionLabel: function getOptionLabel(option) {
      return option[optionLabelKey];
    },
    getOptionValue: function getOptionValue(option) {
      return option[optionValueKey];
    },
    openMenuOnFocus: true,
    styles: _objectSpread({}, optionStyle(customTheme), customStyles),
    options: options,
    isOptionDisabled: isOptionDisabled,
    placeholder: "",
    components: customComponents,
    isClearable: clearable,
    classNamePrefix: "select-".concat(name)
  }, props, {
    autoComplite: props.autoComplete
  })), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};