import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
export var AmountInput = function AmountInput(_ref) {
  var disabled = _ref.disabled,
      quantity = _ref.quantity,
      _ref$minQuantity = _ref.minQuantity,
      minQuantity = _ref$minQuantity === void 0 ? 1 : _ref$minQuantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTooLittle = _useState2[0],
      setIsTooLittle = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isTooMuch = _useState4[0],
      setIsTooMuch = _useState4[1];

  var intl = useIntl();
  useEffect(function () {
    if (quantity !== 0) setIsTooLittle(quantity < minQuantity);
    setIsTooMuch(quantity > maxQuantity);
  }, [quantity, minQuantity, maxQuantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseFloat(evt.target.value);

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }
  };

  var minErrorMessage = [{
    message: intl.formatMessage(commonMessages.minQtyIs, {
      minQuantity: minQuantity
    })
  }];
  var maxErrorMessage = [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }];
  var quantityErrors = isTooLittle && minErrorMessage || isTooMuch && maxErrorMessage || undefined;
  return React.createElement(TextField, {
    name: "amount",
    type: "number",
    label: intl.formatMessage(commonMessages.amount, {
      currency: "€"
    }),
    min: minQuantity // ? - 0.1
    ,
    max: maxQuantity // ? + 1
    ,
    value: quantity === 0 ? undefined : quantity,
    step: 0.05,
    required: true,
    disabled: disabled,
    onChange: handleQuantityChange,
    errors: quantityErrors,
    "data-test": testingContext,
    "data-test-id": testingContextId
  });
};
AmountInput.displayName = "AmountInput";
export default AmountInput;