import React from "react";
import { FormattedMessage } from "react-intl";
import AddToCartButton from "./AddToCartButton";

var AddToCart = function AddToCart(_ref) {
  var onSubmit = _ref.onSubmit,
      disabled = _ref.disabled;
  return React.createElement(AddToCartButton, {
    testingContext: "addProductToCartButton",
    className: "product-description__action",
    onClick: function onClick() {
      onSubmit();
    },
    disabled: disabled
  }, React.createElement(FormattedMessage, {
    id: "components.TesiProductDescription.AddToCart.81047562",
    defaultMessage: "Add to basket"
  }));
};

export default AddToCart;