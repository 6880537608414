import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAuth } from "@saleor/sdk";
import { commonMessages, payMessages } from "@temp/intl";
import { MONEY } from "@temp/consts";
import { Button, RichTextContent } from "@components/atoms";
import { AmountInput } from "@components/molecules/AmountInput";
import { OfflinePlaceholder } from "@temp/components";
import { useHistory } from "react-router";
import * as S from "./styles";

var Page = function Page(_ref) {
  var product = _ref.product,
      addToCart = _ref.addToCart,
      remove = _ref.remove,
      isOnline = _ref.isOnline,
      items = _ref.items,
      amount = _ref.amount,
      backgroundImage = _ref.backgroundImage,
      _ref$minQuantity = _ref.minQuantity,
      minQuantity = _ref$minQuantity === void 0 ? 0.5 : _ref$minQuantity,
      _ref$maxQuantity = _ref.maxQuantity,
      maxQuantity = _ref$maxQuantity === void 0 ? 9999 : _ref$maxQuantity;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var history = useHistory();
  var descriptionJson = product.descriptionJson;
  var eurosPVariant = product === null || product === void 0 ? void 0 : product.variants.find(function (variant) {
    return variant.sku === MONEY.euros.sku;
  });
  var coinsPVariant = product === null || product === void 0 ? void 0 : product.variants.find(function (variant) {
    return variant.sku === MONEY.coins.sku;
  });
  var noProducts = !eurosPVariant || !coinsPVariant;

  var _useState = useState({
    eurosQty: 0,
    coinsQty: 0,
    error: false
  }),
      _useState2 = _slicedToArray(_useState, 2),
      payAmount = _useState2[0],
      setPayAmount = _useState2[1];

  useEffect(function () {
    // console.log("clearCart items:", items);
    if (items === null || items === void 0 ? void 0 : items.length) {
      items.forEach(function (item) {
        return remove(item.variant.id);
      }); // console.log("Cart cleared");
    }
  }, [items]);
  useEffect(function () {
    return handlerSetAmount(amount);
  }, [amount]);

  var handlerSetAmount = function handlerSetAmount(amount) {
    var coinsQty = Math.round(100 * amount) % 100;
    var eurosQty = Math.floor(amount);
    var error = !amount || minQuantity > amount || amount > maxQuantity;
    setPayAmount({
      eurosQty: eurosQty,
      coinsQty: coinsQty,
      error: error
    });
  };

  var handleSubmitMoney = function handleSubmitMoney() {
    if (payAmount.eurosQty > 0) addToCart(eurosPVariant.id, payAmount.eurosQty, eurosPVariant.images[0]);
    if (payAmount.coinsQty > 0) addToCart(coinsPVariant.id, payAmount.coinsQty, coinsPVariant.images[0]);
    if (!payAmount.error && !noProducts) history.push(user ? "/checkout/" : "/login/");
  };

  var otherStyles = _objectSpread({}, backgroundImage && {
    backgroundImage: "url(".concat(backgroundImage.url, ")")
  });

  return React.createElement("div", {
    className: "pages-preview__hero",
    style: _objectSpread({
      height: "auto"
    }, otherStyles)
  }, React.createElement("div", null, React.createElement("span", {
    className: "pages-preview__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, payMessages.title)))), isOnline ? React.createElement(S.PayForm, {
    onSubmit: handleSubmitMoney,
    "data-test": "payForm"
  }, React.createElement(S.Input, null, React.createElement(S.HelpText, null, React.createElement(FormattedMessage, payMessages.payDescription1), React.createElement("br", null), React.createElement(FormattedMessage, payMessages.payDescription2)), React.createElement(S.InputWithButton, null, React.createElement(S.QuantityInput, null, React.createElement(AmountInput, {
    quantity: payAmount.coinsQty / 100 + payAmount.eurosQty,
    onQuantityChange: handlerSetAmount,
    testingContext: "addPayAmount",
    minQuantity: minQuantity,
    maxQuantity: maxQuantity,
    disabled: false
  })), React.createElement(S.ButtonWrapper, null, React.createElement(Button, {
    type: "button",
    testingContext: "addMoneyToCartAndGotoCheckout",
    onClick: handleSubmitMoney,
    disabled: noProducts || !!payAmount.error
  }, React.createElement(FormattedMessage, commonMessages.checkout))))), descriptionJson && React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  })) : React.createElement(OfflinePlaceholder, null));
};

export default Page;