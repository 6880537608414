import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import Iframe from "react-iframe";
import * as S from "./styles";

/**
 * Review order view showed in checkout.
 */
var CheckoutReview = function CheckoutReview(_ref) {
  var shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors,
      iframe = _ref.iframe;
  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, iframe ? React.createElement(React.Fragment, null, React.createElement(S.Info3D, null, "Questa finestra \xE8 gestita dalla tua banca, se dovessi avere qualche problema contattaci a stampa@guug.it!"), React.createElement(S.IframeBox, null, React.createElement(S.IframeContainer, null, React.createElement(Iframe, {
    url: iframe,
    width: "100%",
    height: "600px",
    id: "myId",
    className: "myClassname",
    position: "relative"
  })))) : React.createElement(React.Fragment, null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", {
    "data-test": "billingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.4282475982",
    defaultMessage: "Billing Address"
  })), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: billingAddress,
    email: email
  })), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.3577164776",
    defaultMessage: "Shipping Method"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "shippingMethodName"
  }, shippingMethodName)), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutReview.CheckoutReview.348821000",
    defaultMessage: "Payment Method"
  })), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, paymentMethodName)))), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { CheckoutReview };