import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useInView } from "react-intersection-observer";
import { Icon } from "@components/atoms";
import { CachedImage } from "@components/molecules";
import * as S from "./styles";
var MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS = 4;
export var TesiProductGallery = function TesiProductGallery(_ref) {
  var _activeProductImage$i;

  var images = _ref.images,
      activeFrontespiece = _ref.activeFrontespiece,
      activeProductImage = _ref.activeProductImage;
  var displayButtons = images.length > MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS;

  var _React$useState = React.useState(activeProductImage.item),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      selectedProductimage = _React$useState2[0],
      setSelectedProductimage = _React$useState2[1];

  var bottomImageRef = React.useRef(null);
  var topImageRef = React.useRef(null);

  var _useInView = useInView({
    threshold: 0.5
  }),
      _useInView2 = _slicedToArray(_useInView, 2),
      topImageIntersectionObserver = _useInView2[0],
      topImageInView = _useInView2[1];

  var _useInView3 = useInView({
    threshold: 0.5
  }),
      _useInView4 = _slicedToArray(_useInView3, 2),
      bottomImageIntersectionObserver = _useInView4[0],
      bottomImageInView = _useInView4[1];

  var setBottomRef = React.useCallback(function (node) {
    bottomImageRef.current = node;
    bottomImageIntersectionObserver(node);
  }, [bottomImageIntersectionObserver]);
  var setTopRef = React.useCallback(function (node) {
    topImageRef.current = node;
    topImageIntersectionObserver(node);
  }, [topImageIntersectionObserver]);

  var setIntersectionObserver = function setIntersectionObserver(index, lengthOfArray) {
    if (lengthOfArray > MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS) {
      if (index === 0) {
        return setTopRef;
      }

      if (index === lengthOfArray - 1) {
        return setBottomRef;
      }
    }
  };

  return React.createElement(S.Wrapper, {
    "data-test": "productPhotosGallery"
  }, React.createElement(S.ThumbnailsContainer, null, !topImageInView && displayButtons && React.createElement(S.TopButton, {
    onClick: function onClick() {
      if (topImageRef.current) {
        topImageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      }
    }
  }, React.createElement(Icon, {
    name: "select_arrow",
    size: 10
  })), !bottomImageInView && displayButtons && React.createElement(S.BottomButton, {
    onClick: function onClick() {
      if (bottomImageRef.current) {
        bottomImageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      }
    }
  }, React.createElement(Icon, {
    name: "select_arrow",
    size: 10
  })), React.createElement(S.ThumbnailList, null, React.createElement("ul", null, images && images.length > 0 && images.map(function (image, index) {
    return React.createElement("li", {
      className: "pulsable",
      key: index,
      "data-test": "galleryThumbnail",
      "data-test-id": index
    }, React.createElement(S.Thumbnail, {
      className: image === selectedProductimage ? "click" : "",
      ref: setIntersectionObserver(index, images.length),
      onClick: function onClick() {
        setSelectedProductimage(image);
        activeProductImage.set(image);
      },
      onMouseEnter: function onMouseEnter() {
        return activeProductImage.set(image);
      },
      onMouseLeave: function onMouseLeave() {
        if (activeProductImage.item !== selectedProductimage) activeProductImage.set(selectedProductimage);
      },
      activeThumbnail: selectedProductimage === image
    }, React.createElement(CachedImage, {
      alt: image.alt,
      url: image.url
    })));
  })))), React.createElement(S.Preview, {
    "data-test": "imagePreview"
  }, React.createElement("div", _extends({
    className: "cover-preview"
  }, activeProductImage && {
    style: {
      backgroundImage: "url(".concat(activeProductImage === null || activeProductImage === void 0 ? void 0 : activeProductImage.item.url, ")")
    }
  }), activeFrontespiece ? React.createElement(CachedImage, {
    alt: "text-color: ".concat(activeFrontespiece.alt, ",\ncover: ").concat(activeProductImage === null || activeProductImage === void 0 ? void 0 : (_activeProductImage$i = activeProductImage.item) === null || _activeProductImage$i === void 0 ? void 0 : _activeProductImage$i.alt),
    url: activeFrontespiece.url
  }) : React.createElement(CachedImage, null))));
};