import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Checkbox, ErrorMessage } from "@components/atoms";
import { uploadFileMessages } from "@temp/intl";
import * as S from "./styles";
/**
 * Terms&Conditions and Treatment form used in checkout.
 */

var CheckboxForm = function CheckboxForm(_ref) {
  var termsAndDataErrors = _ref.termsAndDataErrors,
      setTermsAndDataErrors = _ref.setTermsAndDataErrors;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      termsAndConditions = _useState2[0],
      setTermsAndConditions = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      dataProcessing = _useState4[0],
      setDataProcessing = _useState4[1];

  var intl = useIntl();
  useEffect(function () {
    if (!termsAndConditions) {
      setTermsAndDataErrors([{
        field: "terms",
        message: intl.formatMessage(uploadFileMessages.acceptTerms)
      }]);
      return;
    }

    if (!dataProcessing) {
      setTermsAndDataErrors([{
        field: "dates",
        message: intl.formatMessage(uploadFileMessages.acceptDates)
      }]);
      return;
    }

    setTermsAndDataErrors([]);
  }, [termsAndConditions, dataProcessing]);
  return React.createElement(S.Wrapper, null, React.createElement(Checkbox, {
    "data-test": "checkoutTermsAndConditionsCheckbox",
    name: "terms-and-conditions",
    checked: termsAndConditions,
    onChange: function onChange() {
      return setTermsAndConditions(!termsAndConditions);
    }
  }, React.createElement(FormattedMessage, uploadFileMessages.haveReadAndAccept), React.createElement(S.Link, {
    target: "_blank",
    href: "https://guug.it/termini-e-condizioni"
  }, React.createElement(S.TextEmphasis, null, React.createElement(FormattedMessage, uploadFileMessages.termsAndConditions)))), React.createElement(Checkbox, {
    "data-test": "checkoutDataProcessing",
    name: "data-processing",
    checked: dataProcessing,
    onChange: function onChange() {
      return setDataProcessing(!dataProcessing);
    }
  }, React.createElement(FormattedMessage, uploadFileMessages.haveReadAndAccept), React.createElement(S.Link, {
    target: "_blank",
    href: "https://guug.it/termini-e-condizioni",
    rel: "noopener noreferrer"
  }, React.createElement(S.TextEmphasis, null, React.createElement(FormattedMessage, uploadFileMessages.dataTreetmant)))), React.createElement(ErrorMessage, {
    errors: termsAndDataErrors
  }));
};

export { CheckboxForm };