import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  input[type=\"radio\"] {\n    opacity: 0;\n    position: fixed;\n    width: 0;\n  }\n  > div {\n    display: inline-block;\n    width: 1em;\n    min-width: 1em;\n    height: 1em;\n    margin: 0.25em 1em 0.25em 0.25em;\n    border: 0.1em solid #21125e;\n    border-radius: 0.5em;\n    background: #ffffff;\n    vertical-align: bottom;\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from "styled-components";
import { styled } from "@styles";
var inputStyle = css(_templateObject(), function (props) {
  return props.checked && "color: #21125E;";
}, function (props) {
  return props.checked && "> div > span {\n      display: block;\n      width: 0.5em;\n      height: 0.5em;\n      margin: 0.125em;\n      border-radius: 0.25em;\n      background: #21125e;\n    }";
});
export var Input = styled.div(_templateObject2(), inputStyle);
export var LabeledInput = styled.label(_templateObject3(), inputStyle);