import React, { useState, useEffect } from "react";

const View = () => {
  useEffect(() => {
    // console.log("3DS-authentication-complete");
    // eslint-disable-next-line no-undef
    if (window) {
      // eslint-disable-next-line no-undef
      window?.top?.postMessage("3DS-authentication-complete", "*");
    }
  }, []);

  return (
    <h3>
      Il processo di autentificazione 3D secure della tua banca è finito.
      <br />
      Ti stiamo redirezionando a shop.guug.it
    </h3>
  );
};

export default View;
