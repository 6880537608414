import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 4px 5px;\n  background-color: rgba(255, 255, 255, 0.7);\n  max-width: 840px;\n  width: fit-content;\n  margin: 0 auto 8px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  padding-bottom: 43px;\n  height: 85px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\ndisplay: block;\nbackground-color: ", ";\npadding: 10px;\n", "\nfont-size: ", ";\ncursor: pointer;\ntext-align: left;\nmargin: 0 auto;\nwidth: 70%;\nmin-width: 300px;\nmax-width: 800px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Tile = styled.label(_templateObject(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var FileNavigation = styled.div(_templateObject2());
export var HelpText = styled.div(_templateObject3());