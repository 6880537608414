import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { uploadFileMessages } from "@temp/intl";
import { getDBIdFromGraphqlId as getDBId } from "@utils/core";
import { uploadBaseUrl } from "../../app/routes";
import { HelpText } from "./styles";

var Page = function Page(_ref) {
  var product = _ref.product,
      isOnline = _ref.isOnline,
      fileType = _ref.fileType,
      fileHash = _ref.fileHash,
      backgroundImage = _ref.backgroundImage;
  var url = "".concat(uploadBaseUrl).concat(product.slug, "/").concat(getDBId(product.id), "/");
  var end = "/".concat(fileType, "/").concat(fileHash).concat(window.location.hash);

  var otherStyles = _objectSpread({}, backgroundImage && {
    backgroundImage: "url(".concat(backgroundImage.url, ")")
  });

  return React.createElement("div", {
    className: "select-format__hero",
    style: _objectSpread({
      height: "auto"
    }, otherStyles)
  }, React.createElement("div", {
    className: "select-format__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "select-format__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, uploadFileMessages.selectFormat))), React.createElement(HelpText, null, React.createElement(FormattedMessage, uploadFileMessages.formatDescription1), React.createElement("br", null))), React.createElement("div", {
    className: "select-format__container"
  }, React.createElement("div", {
    className: "photo-format__buttons"
  }, React.createElement("div", {
    className: "misure"
  }, product.variants.map(function (variant) {
    var _variant$images$, _variant$images$2;

    return React.createElement("div", {
      key: variant.id
    }, React.createElement(Link, {
      to: "".concat(url).concat(getDBId(variant.id)).concat(end)
    }, React.createElement("span", {
      title: variant.name
    }, variant.name), React.createElement("img", {
      src: (_variant$images$ = variant.images[0]) === null || _variant$images$ === void 0 ? void 0 : _variant$images$.url,
      alt: (_variant$images$2 = variant.images[0]) === null || _variant$images$2 === void 0 ? void 0 : _variant$images$2.alt,
      title: variant.name
    }), React.createElement("span", {
      title: variant.pricing.price.gross.currency
    }, variant.pricing.price.gross.amount, " \u20AC")));
  }))))));
};

export default Page;