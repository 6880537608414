import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    italianMessage: "Cerca",
    defaultMessage: "search"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    italianMessage: "Esaurito",
    defaultMessage: "Out of stock"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    italianMessage: "Pochi pezzi rimasti",
    defaultMessage: "Low stock"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    italianMessage: "Non disponibile",
    defaultMessage: "No items available"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Will become available for purchase on {date} at {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    italianMessage: "Potrebbe piacerti anche",
    defaultMessage: "You might like"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    italianMessage: "Scegli metodo di pagamento.",
    defaultMessage: "Please choose payment method."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    italianMessage: "Inserisci indirizzo e-mail.",
    defaultMessage: "Please provide email address."
  },
  account: {
    "id": "intl.commonMessages.account",
    italianMessage: "Account",
    defaultMessage: "Account"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    italianMessage: "Il mio Account",
    defaultMessage: "My Account"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    italianMessage: "Ordini precedenti",
    defaultMessage: "Order history"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    italianMessage: "Lista di indirizzi",
    defaultMessage: "Address book"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    italianMessage: "Esci",
    defaultMessage: "Log Out"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    italianMessage: "Nome",
    defaultMessage: "First Name"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    italianMessage: "Cognome",
    defaultMessage: "Last Name"
  },
  password: {
    "id": "intl.commonMessages.password",
    italianMessage: "Password",
    defaultMessage: "Password"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    italianMessage: "Quantità",
    defaultMessage: "Quantity"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    italianMessage: "SKU",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    italianMessage: "Quantità massima è {maxQuantity}",
    defaultMessage: "Maximum quantity is {maxQuantity}"
  },
  minQtyIs: {
    "id": "intl.commonMessages.minQtyIs",
    italianMessage: "Quantità minima è {minQuantity}",
    defaultMessage: "Minimum quantity is {minQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    italianMessage: "Quantità",
    defaultMessage: "Quantity"
  },
  amount: {
    "id": "intl.commonMessages.amount",
    italianMessage: "Importo {currency}",
    defaultMessage: "Amount {currency}"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    italianMessage: "Subtotale",
    defaultMessage: "Subtotal"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    italianMessage: "Spedizione",
    defaultMessage: "Shipping"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    italianMessage: "Codice Promozionale",
    defaultMessage: "Promo code"
  },
  total: {
    "id": "intl.commonMessages.total",
    italianMessage: "Totale",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    italianMessage: "Prezzo Totale",
    defaultMessage: "Total Price"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    italianMessage: "Acquista",
    defaultMessage: "Checkout"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    italianMessage: "Indirizzo e-mail",
    defaultMessage: "Email Address"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    italianMessage: "E-mail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    italianMessage: "Caricamento",
    defaultMessage: "Loading"
  },
  products: {
    "id": "intl.commonMessages.products",
    italianMessage: "Prodotti",
    defaultMessage: "Products"
  },
  price: {
    "id": "intl.commonMessages.price",
    italianMessage: "Prezzo",
    defaultMessage: "Price"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    italianMessage: "Varianti",
    defaultMessage: "Variant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    italianMessage: "Telefono",
    defaultMessage: "Phone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    italianMessage: "Numero di telefono: {phone}",
    defaultMessage: "Phone number: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    italianMessage: "E-mail: {email}",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    italianMessage: "Salva",
    defaultMessage: "Save"
  },
  add: {
    "id": "intl.commonMessages.add",
    italianMessage: "Aggiungi",
    defaultMessage: "Add"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    italianMessage: "Filtri",
    defaultMessage: "FILTERS"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    italianMessage: "Togli filtri",
    defaultMessage: "CLEAR FILTERS"
  },
  status: {
    "id": "intl.commonMessages.status",
    italianMessage: "Stato",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    italianMessage: "Cancella",
    defaultMessage: "Cancel"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Home"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    italianMessage: "Indirizzo",
    defaultMessage: "Address"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    italianMessage: "Spedizione",
    defaultMessage: "Shipping"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    italianMessage: "Pagamento",
    defaultMessage: "Payment"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    italianMessage: "Revisiona",
    defaultMessage: "Review"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    italianMessage: "Procedi",
    defaultMessage: "Continue to Shipping"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    italianMessage: "Vai a Pagamento",
    defaultMessage: "Continue to Payment"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    italianMessage: "Vai a Revisiona",
    defaultMessage: "Continue to Review"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    italianMessage: "Fai l'ordine",
    defaultMessage: "Place order"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    italianMessage: "Aggiungi nuovo indirizzo",
    defaultMessage: "Add new address"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    italianMessage: "Metodo di spedizioni",
    defaultMessage: "SHIPPING METHOD"
  },
  shippingMethodDescription1: {
    "id": "intl.checkoutMessages.shippingMethodDescription1",
    italianMessage: "Scegli se ritirare il tuo ordine nei nostri punti vendita oppure se riceverlo direttamente a casa tua",
    defaultMessage: "Choose whether to collect your order in our stores or to receive it directly at your home"
  },
  shippingMethodDescription2: {
    "id": "intl.checkoutMessages.shippingMethodDescription2",
    italianMessage: "(in questo caso Il sistema calcolerà automaticamente le spese di spedizione).",
    defaultMessage: "(in this case the system will automatically calculate the shipping costs)."
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    italianMessage: "Indirizzo di fatturazione",
    defaultMessage: "BILLING ADDRESS"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    italianMessage: "Metodo di pagamento",
    defaultMessage: "PAYMENT METHOD"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    italianMessage: "Rivedi l'ordine",
    defaultMessage: "REVIEW ORDER"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    italianMessage: "Indirizzo di Spedizione",
    defaultMessage: "Shipping Address"
  },
  shippingAddressDescription1: {
    "id": "intl.checkoutMessages.shippingAddressDescription1",
    italianMessage: "Inserisci comunque i dati di spedizione anche se scegli di ritirare presso i nostri punti vendita.",
    defaultMessage: "Enter your shipping information even if you choose to collect at our stores."
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    italianMessage: "Continua a comprare",
    defaultMessage: "CONTINUE SHOPPING"
  },
  stripeLabel: {
    "id": "intl.checkoutMessages.stripeLabel",
    italianMessage: "CARTA DI CREDITO",
    defaultMessage: "CREDIT CARD"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    italianMessage: "Elimina...",
    defaultMessage: "Clear..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    italianMessage: "Prezzo: crescente",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    italianMessage: "Prezzo: decrescente",
    defaultMessage: "Price High-Low"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    italianMessage: "Nome: Da A-Z",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    italianMessage: "Nome: Da Z-A",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    italianMessage: "Dal meno al più recente",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    italianMessage: "Dal più al meno recente",
    defaultMessage: "Last updated Descending"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    italianMessage: "Non addebitato",
    defaultMessage: "Not charged"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    italianMessage: "Pagamento parziale",
    defaultMessage: "Partially charged"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    italianMessage: "Pagamento completo",
    defaultMessage: "Fully charged"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    italianMessage: "Rimborsato in parte",
    defaultMessage: "Partially refunded"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    italianMessage: "Rimborsato",
    defaultMessage: "Fully refunded"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    italianMessage: "Bozza",
    defaultMessage: "Draft"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    italianMessage: "Incompleto",
    defaultMessage: "Unfulfilled"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    italianMessage: "Completo parzialmente",
    defaultMessage: "Partially fulfilled"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    italianMessage: "Completo",
    defaultMessage: "Fulfilled"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    italianMessage: "Cancellato",
    defaultMessage: "Canceled"
  }
});
export var uploadFileMessages = defineMessages({
  next: {
    "id": "intl.uploadFileMessages.next",
    italianMessage: "Prossimo",
    defaultMessage: "Next"
  },
  unfulfilled: {
    "id": "intl.uploadFileMessages.unfulfilled",
    italianMessage: "Non evaso",
    defaultMessage: "Unfulfilled"
  },
  fileName: {
    "id": "intl.uploadFileMessages.fileName",
    italianMessage: "Nome File",
    defaultMessage: "File Name"
  },
  pagesPreviewDescription1: {
    "id": "intl.uploadFileMessages.pagesPreviewDescription1",
    italianMessage: "Il sistema riconosce automaticamente le pagine a colori e quelle in bianco e nero e calcola il prezzo.",
    defaultMessage: "The system automatically recognizes color and black and white pages and calculates the price."
  },
  pagesPreviewDescription2: {
    "id": "intl.uploadFileMessages.pagesPreviewDescription2",
    italianMessage: "Puoi comunque scegliere di stampare tutto in bianco e nero oppure lasciare la selezione automatica.",
    defaultMessage: "You can still choose to print everything in black and white or leave the automatic selection."
  },
  pagesPreviewDescription3: {
    "id": "intl.uploadFileMessages.pagesPreviewDescription3",
    italianMessage: "Puoi inoltre scegliere in bianco e nero singole pagine cliccando sulla pagina selezionata.",
    defaultMessage: "You can also choose single pages in black and white by clicking on the selected page."
  },
  formatDescription1: {
    "id": "intl.uploadFileMessages.formatDescription1",
    italianMessage: "Scegli formato e lasci le foto che vuoi stampare di questa misura.",
    defaultMessage: ""
  },
  // formatDescription2: {
  //   italianMessage:
  //     "Per aggiungere al carello e procedere con l'acquisto clicca: Procedi conl'Acquisto.",
  //   defaultMessage: "",
  // },
  // formatDescription3: {
  //   italianMessage:
  //     "Per aggiungere al carello e scegliere le altre foto di altra misura clicca: Carica più.",
  //   defaultMessage: "",
  // },
  uploadAnotherFile: {
    "id": "intl.uploadFileMessages.uploadAnotherFile",
    italianMessage: "Carica di più",
    defaultMessage: "Upload Another",
    "description": "formatDescription2: {\nitalianMessage:\n\"Per aggiungere al carello e procedere con l'acquisto clicca: Procedi conl'Acquisto.\",\ndefaultMessage: \"\",\n},\nformatDescription3: {\nitalianMessage:\n\"Per aggiungere al carello e scegliere le altre foto di altra misura clicca: Carica pi\xF9.\",\ndefaultMessage: \"\",\n},"
  },
  uploadedFile: {
    "id": "intl.uploadFileMessages.uploadedFile",
    italianMessage: "File Caricato",
    defaultMessage: "Uploaded File"
  },
  addToBasket: {
    "id": "intl.uploadFileMessages.addToBasket",
    italianMessage: "Aggiungere al carello",
    defaultMessage: "Add to Basket"
  },
  pagesQty: {
    "id": "intl.uploadFileMessages.pagesQty",
    italianMessage: "Pagine",
    defaultMessage: "Pages"
  },
  selectPages: {
    "id": "intl.uploadFileMessages.selectPages",
    italianMessage: "Scegli Pagine a colori:",
    defaultMessage: "Select Pages in color:"
  },
  selectFormat: {
    "id": "intl.uploadFileMessages.selectFormat",
    italianMessage: "Scegli la Misura delle Foto:",
    defaultMessage: "Choose the size of the photos:"
  },
  selectedColored: {
    "id": "intl.uploadFileMessages.selectedColored",
    italianMessage: "Pagine a colori:",
    defaultMessage: "Pages in color:"
  },
  setAllGrey: {
    "id": "intl.uploadFileMessages.setAllGrey",
    italianMessage: "Tutto bianco e nero:",
    defaultMessage: "Set all Grey"
  },
  setRestColored: {
    "id": "intl.uploadFileMessages.setRestColored",
    italianMessage: "Lascia a colori",
    defaultMessage: "Rest in color"
  },
  stepNameUpload: {
    "id": "intl.uploadFileMessages.stepNameUpload",
    italianMessage: "Carica File",
    defaultMessage: "Upload File"
  },
  stepNameCover: {
    "id": "intl.uploadFileMessages.stepNameCover",
    italianMessage: "Scegli Copertina",
    defaultMessage: "Choose Cover"
  },
  stepNameInnerPages: {
    "id": "intl.uploadFileMessages.stepNameInnerPages",
    italianMessage: "Pagine Interne",
    defaultMessage: "Inner Pages"
  },
  stepNamePlotterFormat: {
    "id": "intl.uploadFileMessages.stepNamePlotterFormat",
    italianMessage: "Select format",
    defaultMessage: "Seleziona formato"
  },
  stepNameCheckout: {
    "id": "intl.uploadFileMessages.stepNameCheckout",
    italianMessage: "Procedi con l'Acquisto",
    defaultMessage: "Go to Checkout"
  },
  selectedCovers: {
    "id": "intl.uploadFileMessages.selectedCovers",
    italianMessage: "Copertine Scelte",
    defaultMessage: "Selected Covers"
  },
  colored: {
    "id": "intl.uploadFileMessages.colored",
    italianMessage: "A colori",
    defaultMessage: "Colored"
  },
  blackWhite: {
    "id": "intl.uploadFileMessages.blackWhite",
    italianMessage: "Bianco e nero",
    defaultMessage: "Black-White"
  },
  filePrintTotal: {
    "id": "intl.uploadFileMessages.filePrintTotal",
    italianMessage: "Totale del File",
    defaultMessage: "This File total"
  },
  innerPages: {
    "id": "intl.uploadFileMessages.innerPages",
    italianMessage: "Pagine interne (Prezzo per ",
    defaultMessage: "Inner Pages (Price per "
  },
  copies: {
    "id": "intl.uploadFileMessages.copies",
    italianMessage: " copie",
    defaultMessage: " copies"
  },
  copy: {
    "id": "intl.uploadFileMessages.copy",
    italianMessage: " copia",
    defaultMessage: " copy"
  },
  acceptTerms: {
    "id": "intl.uploadFileMessages.acceptTerms",
    italianMessage: "Prego accettare Termini e Condzioni per procedere",
    defaultMessage: "Please accept Terms and Conditions to proceed"
  },
  acceptDates: {
    "id": "intl.uploadFileMessages.acceptDates",
    italianMessage: "Prego accettare Trattmento dei dati per procedere",
    defaultMessage: "Please accept Personal Data Processing to proceed"
  },
  haveReadAndAccept: {
    "id": "intl.uploadFileMessages.haveReadAndAccept",
    italianMessage: "Ho letto e sottoscrivo",
    defaultMessage: "I have read and accept"
  },
  termsAndConditions: {
    "id": "intl.uploadFileMessages.termsAndConditions",
    italianMessage: "i termini e le condizioni del servizio",
    defaultMessage: "the Terms and Conditions of the service"
  },
  dataTreetmant: {
    "id": "intl.uploadFileMessages.dataTreetmant",
    italianMessage: "il trattamento dei dati personali",
    defaultMessage: "the Personal Data Processing"
  }
});
export var uploadPDFMessages = defineMessages({
  title: {
    "id": "intl.uploadPDFMessages.title",
    italianMessage: "Carica Documento PDF",
    defaultMessage: "Upload PDF Document"
  },
  uploadFileDescription1: {
    "id": "intl.uploadPDFMessages.uploadFileDescription1",
    italianMessage: "Carica il file che desideri stampare. Se il file non è in PDF si prega di convertirlo.",
    defaultMessage: "Upload file that you want to print. If your file is not in PDF please convert it."
  },
  uploadFileDescription2: {
    "id": "intl.uploadPDFMessages.uploadFileDescription2",
    italianMessage: "(Perché altri tipi di file potrebbero non seguire lo stesso formato in diverse versioni dell'app).",
    defaultMessage: "(Because other file types may not follow the same format in different app versions)."
  }
});
export var uploadThesisMessages = defineMessages({
  title: {
    "id": "intl.uploadThesisMessages.title",
    italianMessage: "Carica Tesi (in PDF)",
    defaultMessage: "Upload Thesis (in PDF)"
  },
  uploadFileDescription1: {
    "id": "intl.uploadThesisMessages.uploadFileDescription1",
    italianMessage: "Carica la tua tesi in PDF comprensiva di frontespizio.",
    defaultMessage: "Upload your thesis in PDF including title page."
  },
  uploadFileDescription2: {
    "id": "intl.uploadThesisMessages.uploadFileDescription2",
    italianMessage: "(Non preoccuparti se il tuo frontespizio non è formattato perfettamente ci pensiamo noi).",
    defaultMessage: "(Don't worry if your cover page isn't perfectly formatted, we'll take care of it)."
  }
});
export var uploadCoverMessages = defineMessages({
  title: {
    "id": "intl.uploadCoverMessages.title",
    italianMessage: "Carica Copertina (in PDF)",
    defaultMessage: "Upload Cover (in PDF)"
  },
  uploadFileDescription1: {
    "id": "intl.uploadCoverMessages.uploadFileDescription1",
    italianMessage: "",
    defaultMessage: ""
  },
  uploadFileDescription2: {
    "id": "intl.uploadCoverMessages.uploadFileDescription2",
    italianMessage: null,
    defaultMessage: null
  }
});
export var uploadPlotterMessages = defineMessages({
  title: {
    "id": "intl.uploadPlotterMessages.title",
    italianMessage: "Carica il File (in PDF)",
    defaultMessage: "Upload the File (in PDF)"
  },
  uploadFileDescription1: {
    "id": "intl.uploadPlotterMessages.uploadFileDescription1",
    italianMessage: "Carica il file che desideri stampare con il plotter. Se il file non è in PDF si prega di convertirlo.",
    defaultMessage: "Upload file that you want to print with plotter. If your file is not in PDF please convert it."
  },
  uploadFileDescription2: {
    "id": "intl.uploadPlotterMessages.uploadFileDescription2",
    italianMessage: "(Perché altri tipi di file potrebbero non seguire lo stesso formato in diverse versioni dell'app).",
    defaultMessage: "(Because other file types may not follow the same format in different app versions)."
  }
});
export var uploadIMGMessages = defineMessages({
  title: {
    "id": "intl.uploadIMGMessages.title",
    italianMessage: "Carica Le tue Foto",
    defaultMessage: "Upload your Images"
  },
  uploadFileDescription1: {
    "id": "intl.uploadIMGMessages.uploadFileDescription1",
    italianMessage: "Carica le foto in formato immagine.",
    defaultMessage: "Upload photos in image format."
  },
  uploadFileDescription2: {
    "id": "intl.uploadIMGMessages.uploadFileDescription2",
    italianMessage: "Può essere fatto un caricamento multiplo dei File.",
    defaultMessage: "Multiple File uploads can be done."
  }
});
export var uploadDOCMessages = defineMessages({
  title: {
    "id": "intl.uploadDOCMessages.title",
    italianMessage: "Carica Documento Word",
    defaultMessage: "Upload Word Document"
  },
  uploadFileDescription1: {
    "id": "intl.uploadDOCMessages.uploadFileDescription1",
    italianMessage: "",
    defaultMessage: ""
  },
  uploadFileDescription2: {
    "id": "intl.uploadDOCMessages.uploadFileDescription2",
    italianMessage: null,
    defaultMessage: null
  }
});
export var uploadPPTMessages = defineMessages({
  title: {
    "id": "intl.uploadPPTMessages.title",
    italianMessage: "Carica Documento PowerPoint",
    defaultMessage: "Upload PowerPoint Document"
  },
  uploadFileDescription1: {
    "id": "intl.uploadPPTMessages.uploadFileDescription1",
    italianMessage: "",
    defaultMessage: ""
  },
  uploadFileDescription2: {
    "id": "intl.uploadPPTMessages.uploadFileDescription2",
    italianMessage: null,
    defaultMessage: null
  }
});
export var uploadOtherMessages = defineMessages({
  title: {
    "id": "intl.uploadOtherMessages.title",
    italianMessage: "Il tipo di file non è supportato",
    defaultMessage: "The file type is not supported"
  },
  uploadFileDescription1: {
    "id": "intl.uploadOtherMessages.uploadFileDescription1",
    italianMessage: "",
    defaultMessage: ""
  },
  uploadFileDescription2: {
    "id": "intl.uploadOtherMessages.uploadFileDescription2",
    italianMessage: null,
    defaultMessage: null
  }
});
export var payMessages = defineMessages({
  title: {
    "id": "intl.payMessages.title",
    italianMessage: "Pagamento per la stampa GuuG",
    defaultMessage: "Payment for Guug printing"
  },
  payDescription1: {
    "id": "intl.payMessages.payDescription1",
    italianMessage: "Scrivi l'importo comunicato nel preventivo",
    defaultMessage: "Write the amount communicated in the estimate"
  },
  payDescription2: {
    "id": "intl.payMessages.payDescription2",
    italianMessage: "(senza il prezzo di spedizione)",
    defaultMessage: "(without shipping price)"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}