import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation UploadFileLinesUpdate($ids: [ID]!, $token: ID!, $hashs: [String]!) {\n    updateFileLines(ids: $ids, hashs: $hashs, checkoutId: $token) {\n      ok\n      fileLines {\n        id\n        hash\n        name\n        order {\n          id\n          token\n        }\n        checkout {\n          token\n        }\n        choosedColoredPages # [1, 5, 11]\n        duplex # false\n        copies # 1\n        role\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
export var fileLinesSetToken = gql(_templateObject());