import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { COVER, INNER_PAGE, PLOTTER } from "@temp/consts";
import { Button, Loader, ProductsFeatured } from "../../components";
import { generateCategoryUrl } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import noPhotoImg from "../../images/no-photo.svg";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var categoriesExist = function categoriesExist() {
    return categories && categories.edges && categories.edges.length > 0;
  };

  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__hero",
    style: backgroundImage ? {
      backgroundImage: "url(".concat(backgroundImage.url, ")")
    } : null
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3585656892",
    defaultMessage: "Benvenuti nel nuovo portale di stampa!"
  })))), false && React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.4025497891",
    defaultMessage: "Spedizione gratis per gli ordini pi\xF9 di 100\u20AC"
  }))))), React.createElement("div", {
    className: "home-page__hero-action"
  }, loading && !categories ? React.createElement(Loader, null) : // categoriesExist() && (
  React.createElement(React.Fragment, null, React.createElement(Link, {
    to: "/upload/".concat(COVER.slug, "/").concat(COVER.id, "/thesis-pdf/")
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1886731923",
    defaultMessage: "Upload Tesi"
  }))), "\xA0\xA0\xA0", React.createElement(Link, {
    to: "/upload/".concat(PLOTTER.slug, "/").concat(PLOTTER.id, "/plotter-pdf/")
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1870912553",
    defaultMessage: "Upload Plotter"
  }))), "\xA0\xA0\xA0", React.createElement(Link, {
    to: "/select-format/img/"
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1827290871",
    defaultMessage: "Upload Images"
  }))), "\xA0\xA0\xA0", React.createElement(Link, {
    to: "/upload/".concat(INNER_PAGE.slug, "/").concat(INNER_PAGE.id, "/pdf/")
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3061841361",
    defaultMessage: "Upload PDF"
  })))) // )
  )), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Featured",
      "id": "views.Home.Page.2812648978"
    }, "id", "views.Home.Page.2812648978"))
  }), categoriesExist() && React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3813745889",
    defaultMessage: "Shop by category"
  })), React.createElement("div", {
    className: "home-page__categories__list"
  }, categories.edges.map(function (_ref2) {
    var category = _ref2.node;
    return React.createElement("div", {
      key: category.id
    }, React.createElement(Link, {
      to: generateCategoryUrl(category.id, category.name),
      key: category.id
    }, React.createElement("div", {
      className: classNames("home-page__categories__list__image", {
        "home-page__categories__list__image--no-photo": !category.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(category.backgroundImage ? category.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("h3", null, category.name)));
  })))));
};

export default Page;